import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Software/Linux/ioBroker/PrimaryBox';
import NavButtons from 'components/Software/Linux/NavButtons';
import ForumBox from 'components/Software/Linux/ioBroker/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker with Docker",
  "path": "/Software/Linux/ioBroker/",
  "dateChanged": "2024-04-02",
  "author": "Mike Polinowski",
  "excerpt": "Adding your INSTAR IP Camera to your ioBroker Home Automation System. Compatible with the camera models IN-8401 2K+ WQHD, IN-8425 2K+ WQHD, IN-9408 2K+ WQHD, IN-9420 2K+ WQHD",
  "image": "./AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='ioBroker with Docker' dateChanged='2024-04-02' author='Mike Polinowski' tag='INSTAR IP Camera' description='Adding your INSTAR IP Camera to your ioBroker Home Automation System. Compatible with the camera models IN-8401 2K+ WQHD, IN-8425 2K+ WQHD, IN-9408 2K+ WQHD, IN-9420 2K+ WQHD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Linux/ioBroker/' locationFR='/fr/Software/Linux/ioBroker/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "iobroker-linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-linux",
        "aria-label": "iobroker linux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker (LINUX)`}</h1>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#iobroker-linux"
        }}>{`ioBroker (LINUX)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#installation"
            }}>{`Installation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#live-video"
            }}>{`Live Video`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#rtsp-mjpeg-and-jpg-streaming"
                }}>{`RTSP, MJPEG and JPG Streaming`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#webrtc"
                }}>{`WebRTC`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-your-camera-live-stream-using-the-onvif-service"
            }}>{`Adding your Camera Live Stream using the ONVIF Service`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#control-your-camera-through-mqtt"
            }}>{`Control your Camera through MQTT`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#connect-your-mqtt-broker"
                }}>{`Connect your MQTT Broker`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#lovelace-ui"
            }}>{`Lovelace UI`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#website-card"
                }}>{`Website Card`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#picture-card"
                }}>{`Picture Card`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <p>{`ioBroker now provides a Docker image that can be pulled from the Docker Hub:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull iobroker/iobroker:latest`}</code></pre></div>
    <p>{`To persist all data generated by ioBroker we can create directories that can later be mounted into the running container. On a Linux host system you also need to set proper read&write permissions, e.g.:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sudo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/iobroker/home
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sudo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` -R `}<span parentName="code" {...{
            "className": "token number"
          }}>{`775`}</span>{` /opt/iobroker/`}</code></pre></div>
    <p>{`The container can now be started as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --privileged `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --name iobroker `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -v /opt/iobroker:/opt/iobroker `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -v /opt/iobroker/home:/home/iobroker `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --detach`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`true `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  iobroker/iobroker:latest`}</code></pre></div>
    <p>{`And the web frontend will come up on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8081`}</code>{` as well as the local IP of your host system:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fc6da8d8397fa710f2dbad520bfff814/84ee5/ioBroker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADAElEQVQoz0XOTWgcBRiH8TkUidK0O7s7u9ndpPu9mZ2Z3fnYmd2d2Wwy+xFrLwEpBoQUjSWU6iW2WpvExkhb8KCWGDWlrTRgDxbaQyOIh+JHhYJ4UMSDolihIhbx7Eke2Umkhx8vvIeHv5B3DNKGxuTskzy3tsTc0iJzSy/uOH2C+dVTHF17hfnVlwMLZ5eZWXiGdFWhqFdRLRPNrqFYbfK6geC3fWYOPkFvshuomzaOXqNhOnTcCSbsJnbFxNEtGoZNQ6/Rtl2mW1NMOS6e6dD32ky1+rSafYStSxvc2b5Kb8rHa7SY9vsYmk7DqrO9/SnnXj9Pu9miVXcx1CqFsSwnXljky9tfceSpp3ENB9/xaGom1UwR4cOPbvDtj/c41JumM+Ez6bYxVR3Pdrl84w5nz10Ilk5P9ei1Oyi5Assr5/nh/j88f/Q4/Wabg55P1/awCwrCx9fe5/fvP8Oz68h5mYqsUcwWadU9vvn6O955a4NiOo8qq1SUCmPJMU6fWuaXn37j2dk5tEyJpmLSrtZwiirC3VtX+Pf+XXzPo6poWFWDQq6I2/B48McDtj7YopQrYFZ1qlqVVHKUtdU1/v7zL44dmccpKfSsBl2rSWO8gvDa4gJX3z6DJpfJZ7LkMzlSiVEUWWVj/T2OLRwnM3aAcrFEIVdgREowe3iWzXcv8rjfw8wVmVB1mmUdMycjnDy5wpvrl7EtB1WpYOgWcqmMFInxyJ4h9j46jLg/QmhfGHF/mEgowvBjwwztGULcGyIpxshFk4yPHKCczCC8+sYm69e/oOm49Dp9un6PmumQHEkhhaUgHBGjgeiuwT8eiREXpUAqEicXH0UeBC+sX+TWJ5/j1l063S66rSPn8yTiiZ1Fg1goEogO7iAaiiKJO2KhaBBNhmNkYymEm9dvcu/nX4PgeLGEouqYkzOkRrOI+8RgUST0UPT/4K4gGIoyMlgajiGsLJ3h0uYVtHKFTCpNqWxhHH6JVFomHARjREXpodDOlUSJ2K7BwkEwIUr8B8Pbok4jdApMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc6da8d8397fa710f2dbad520bfff814/e4706/ioBroker_01.avif 230w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/d1af7/ioBroker_01.avif 460w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/7f308/ioBroker_01.avif 920w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/85f86/ioBroker_01.avif 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fc6da8d8397fa710f2dbad520bfff814/a0b58/ioBroker_01.webp 230w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/bc10c/ioBroker_01.webp 460w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/966d8/ioBroker_01.webp 920w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/ceabe/ioBroker_01.webp 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc6da8d8397fa710f2dbad520bfff814/81c8e/ioBroker_01.png 230w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/08a84/ioBroker_01.png 460w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/c0255/ioBroker_01.png 920w", "/en/static/fc6da8d8397fa710f2dbad520bfff814/84ee5/ioBroker_01.png 1076w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fc6da8d8397fa710f2dbad520bfff814/c0255/ioBroker_01.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#live-video",
        "aria-label": "live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Live Video`}</h2>
    <h3 {...{
      "id": "rtsp-mjpeg-and-jpg-streaming",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#rtsp-mjpeg-and-jpg-streaming",
        "aria-label": "rtsp mjpeg and jpg streaming permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTSP, MJPEG and JPG Streaming`}</h3>
    <p>{`We recommend that you use the `}<a parentName="p" {...{
        "href": "#adding-your-camera-live-stream-using-the-onvif-service"
      }}>{`ONVIF Service`}</a>{` described below to add your camera's live video. But you can also `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WQHD_with_ioBroker/"
      }}>{`add the stream manually`}</a>{` using your cameras `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9420_WQHD/Video_Streaming/"
      }}>{`Snapshot or MJPEG Stream`}</a>{`.`}</p>
    <h3 {...{
      "id": "webrtc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#webrtc",
        "aria-label": "webrtc permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WebRTC`}</h3>
    <p>{`It is usually considered to be more stable to use WebRTC for your camera video stream. Your camera does not yet provide a WebRTC service - but you can use `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WebRTC_Livevideo_Smarthome/"
      }}>{`go2RTC to re-stream your camera's RTSP stream`}</a>{`. This setup is a little bit more advanced and requires starting a Docker container and have it running parallel to your ioBroker container.`}</p>
    <h2 {...{
      "id": "adding-your-camera-live-stream-using-the-onvif-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-camera-live-stream-using-the-onvif-service",
        "aria-label": "adding your camera live stream using the onvif service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your Camera Live Stream using the ONVIF Service`}</h2>
    <p>{`Start by making sure that the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/ONVIF/"
      }}>{`ONVIF service`}</a>{` is actually active inside your cameras webUI. We will need the `}<strong parentName="p">{`ONVIF WS-Security`}</strong>{` to enable ioBroker to connect - but I will leave it `}<strong parentName="p">{`deactivated here`}</strong>{` for the admin user and create a user with limited rights for the ONVIF sevrice in the next step:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "902px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7217e5ea0225ba745668fbd69935b497/58213/Home_Assistant_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABjUlEQVQoz22PzUsbQRjGc8u9C5sqYWYhl8xmD4kYiWspNavZ3WR3spl3ZnbGLRatgpbSQCFgxCiIX9j/oZSeWttTwf+vJBsQP348h/fy43neQr1edxzHtom3cxEd/el8/dke/mh/eSGbw+/Z5X24PVowDYRwpVIp1Go127ar1Wp8eL19+U9OfonJbzG5e57353/3bu/X6MfXpoGxhRAq2Lad+x3vnRaJHEQKqOZ9BVRBrPns5jRlEQvXWbfdWlkul8sYIYzxXCaEBEGwlWVpqpTSSmmtt9JUCSnzAJdweJZ+OnXdNYwxQsiyrEdylmVCCD5DCA4Agwcg/DCOd49WXdeyppvnsx3HIYT4vi+lZE8AYMCB8z6lb1ebm+tvWq1W3jydTQjJf/Z9X2stZwBArjLaHfQ6LA69qOcGwQalzWbTMIxHct4shJiV5XBgCd8bqeENPzgZJ/yqz06YWGo0isXi4sKUh9me5yVJQimN59C41+3vjAafv8X7p+Mgvt4IjsNoqdF4ZRimaZZKpf9bFZqke7+UsAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7217e5ea0225ba745668fbd69935b497/e4706/Home_Assistant_02.avif 230w", "/en/static/7217e5ea0225ba745668fbd69935b497/d1af7/Home_Assistant_02.avif 460w", "/en/static/7217e5ea0225ba745668fbd69935b497/4d463/Home_Assistant_02.avif 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7217e5ea0225ba745668fbd69935b497/a0b58/Home_Assistant_02.webp 230w", "/en/static/7217e5ea0225ba745668fbd69935b497/bc10c/Home_Assistant_02.webp 460w", "/en/static/7217e5ea0225ba745668fbd69935b497/5cb76/Home_Assistant_02.webp 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7217e5ea0225ba745668fbd69935b497/81c8e/Home_Assistant_02.png 230w", "/en/static/7217e5ea0225ba745668fbd69935b497/08a84/Home_Assistant_02.png 460w", "/en/static/7217e5ea0225ba745668fbd69935b497/58213/Home_Assistant_02.png 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7217e5ea0225ba745668fbd69935b497/58213/Home_Assistant_02.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To create an ONVIF user open the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/System/User/"
      }}>{`User Management Menu`}</a>{` and click the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`+`}</code>{` icon to create a new user with the `}<strong parentName="p">{`Guest Preset`}</strong>{` and the __ONVIF (Admin) role:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6d5b1a78befc40ecc2ef52fd63448d87/4255a/Home_Assistant_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABSElEQVQY012OW0vCcBjG9xWCYB2k/cPV1g7eLMlU1EEaxE65zXmY7n9sGuVNl32FBLv2C4Xe19cJtyTqx4/34oWH5+FKeklVNVk6v2qHjejl+v654j2VnUfDnv2zbE9v4atp9Q8PeFEUATjldF3X9NJZEXjzZbr6HCw+4uUmXm56i3Xv7Y+T9/Vs9dUI5vz+XrEoAiBwSsaFLLeaTc91XMdyHTu7lufYv7q2c2e5xC63Lo/4o5MMTs2QJKnTviEYjePRcNDHCCXJJI5HECbDQT+ZjMfxCEOIHpJKvVI4LggZnKZpP+FOZzqdUUoRQmmaUkoJIZRSCGH+pJQyymq1OgBAEIRts5aRh9M0xRhDCBljCCGMMSEkSRJCCEKIEMIYq1ar+eZts6qqiqLIsmyaZhRF/o5ut+v7frgjCALfD8IwNAyD5/l89jdDEXzRVpBDNAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d5b1a78befc40ecc2ef52fd63448d87/e4706/Home_Assistant_03.avif 230w", "/en/static/6d5b1a78befc40ecc2ef52fd63448d87/d1af7/Home_Assistant_03.avif 460w", "/en/static/6d5b1a78befc40ecc2ef52fd63448d87/6e770/Home_Assistant_03.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6d5b1a78befc40ecc2ef52fd63448d87/a0b58/Home_Assistant_03.webp 230w", "/en/static/6d5b1a78befc40ecc2ef52fd63448d87/bc10c/Home_Assistant_03.webp 460w", "/en/static/6d5b1a78befc40ecc2ef52fd63448d87/632b0/Home_Assistant_03.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d5b1a78befc40ecc2ef52fd63448d87/81c8e/Home_Assistant_03.png 230w", "/en/static/6d5b1a78befc40ecc2ef52fd63448d87/08a84/Home_Assistant_03.png 460w", "/en/static/6d5b1a78befc40ecc2ef52fd63448d87/4255a/Home_Assistant_03.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6d5b1a78befc40ecc2ef52fd63448d87/4255a/Home_Assistant_03.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "897px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c992d0bdeed623fbf0608605967c5709/3a737/Home_Assistant_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADK0lEQVQ4y3WSzW8bRRjGfY+UU0ipHXt3nQ/b3ew6TmNq75hQiL1sxrs7O98zu+u0Em0ikBDihJSIiFNPTYED3KjEAYkTfwJHVAtx5t9Bu7ZbQcOjV6M5zKPf+77PVBzH7bR2+h8h/+mz4/z6OL8G+mqoLh/If9VAXY70Jfvy++GHp+/eeccwzO3t7cq+4+42Gyfqi89e/q2e/5F+O8+/m8ubV/z5K3kzly/m8mYububqxfyTH/968sOfDoB372yYlmWaZsW27VarNRwMKEZJPF0UQSGankJ/fOqP4ar88UkIA9u+V61WDcOo1+tLs+d5aZoyxshKIYqCKPBDfwInixrDcYTCbrdbr9cbpSr7+/vtdtvzgNZaKSmEIIRgjMM4hDGEMZycThY1DsZhHPZ6PcuyGo2GYRiF+TVZKSU4X5CDj4OHHzw8Hr0/AoVGYOQNvSAIut1uo9FYwJdkAAoyYwxjXJAJOZlMPDB6MBgOBsNhcQ76/b7v+47jVKvVJdm27b29Pc/zlFKcc1o6OcE8nCLok+kknPp+5MMI4gRTSk3TXFtbMwyjVqu9WZjWSkrJCzhhFAtKBCOKE8EJ4ZQJrrXmnFuWtb6+vrGxsbm5+abts0ePX3cupOJCCCEp45gpIRWjDCGEMbZtu1arbW1tFVGV5pYHgM4fF50LXpj1TKeZUopSqpTOskxKuVik4zimaTZLVRzHKaPy8jzX5cIJIakulOpUCKGULO6pXqToOI5hGFap5cygNCtdvFhGXYSNyVv6r7kgA6Czs2JmzgnGYiXGGF+JUno72fOA0sWQZWAsiiIIYRAEvu8jhISQnPPF572FDACYzfIsS7MsZYwihMIwhIWmCCWk/LGUEPp/M3OZIioxV4RQKWXZhNSCMRwnGEWURpQm9NaZPXAxI5+fxZ/miFGCMcFJgikn2TmeXQgxexrHFyjJE2y/TR564OVX6Pdn09++hpmkhHFGEpY9Sa9+lt/8en5+/VMAfwnJVZS0Xdc0jKbVLMydTqfdbruue78/OLj/Xvew3+sdrtQ7PHB7XefgwHWPjtyjo16/3+7ca+7sNnf2mju7/wCRZkL1sjgNwQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c992d0bdeed623fbf0608605967c5709/e4706/Home_Assistant_04.avif 230w", "/en/static/c992d0bdeed623fbf0608605967c5709/d1af7/Home_Assistant_04.avif 460w", "/en/static/c992d0bdeed623fbf0608605967c5709/c400a/Home_Assistant_04.avif 897w"],
              "sizes": "(max-width: 897px) 100vw, 897px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c992d0bdeed623fbf0608605967c5709/a0b58/Home_Assistant_04.webp 230w", "/en/static/c992d0bdeed623fbf0608605967c5709/bc10c/Home_Assistant_04.webp 460w", "/en/static/c992d0bdeed623fbf0608605967c5709/10735/Home_Assistant_04.webp 897w"],
              "sizes": "(max-width: 897px) 100vw, 897px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c992d0bdeed623fbf0608605967c5709/81c8e/Home_Assistant_04.png 230w", "/en/static/c992d0bdeed623fbf0608605967c5709/08a84/Home_Assistant_04.png 460w", "/en/static/c992d0bdeed623fbf0608605967c5709/3a737/Home_Assistant_04.png 897w"],
              "sizes": "(max-width: 897px) 100vw, 897px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c992d0bdeed623fbf0608605967c5709/3a737/Home_Assistant_04.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back on the ioBroker go into the `}<strong parentName="p">{`Adapter`}</strong>{` menu and open install the ONVIF adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2ee5ff9df007bd007d91529b76771f53/97bfd/ioBroker_ONVIF_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABcUlEQVQoz22Qy27TQBhGvUQqOK4RuRTfZxJ7PGMnjmmIS81l0VWBKOoCiiJWlSqxYINY8gIIdrwOrHivg2xEpSosjuZffN/5pLGOQkEQJbiHDlII2rZlvV7TNA1VVbFaHVPqDDUT5KlEqwylS7TWFFWNMYZqVTGvF/1tvf7+i1fffvLi62+qzTVFJinnC4qyK+U8bk44OX9D8/KS082Odvue08076qdn5Nr04vpRzbJeUhQF1vHZlsWzc6Llc6KsJFcZeZ7/RSnyYs6Ttx9pd59odp9pLz+w/fKD5uKKTERoY1BK9XQdKw58ZBwyOLjDw8kYUxQ3wm49TVOmU8GD8X3suwc49j0GwwAvTDBG3+T+YRkVM5URA8fF9/3+H24JZympSpFaEoYhruty6NiEYbCX7YU6DRCJj20P8H3vVqi7Z7MZWZZRLSriOMa2bUajEVLKXrAnHA7HTCZHOI6D53l7q93blZMk6RFC9HRD/xP+AZaK1RJGkF3+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ee5ff9df007bd007d91529b76771f53/e4706/ioBroker_ONVIF_1.avif 230w", "/en/static/2ee5ff9df007bd007d91529b76771f53/d1af7/ioBroker_ONVIF_1.avif 460w", "/en/static/2ee5ff9df007bd007d91529b76771f53/7f308/ioBroker_ONVIF_1.avif 920w", "/en/static/2ee5ff9df007bd007d91529b76771f53/006c2/ioBroker_ONVIF_1.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2ee5ff9df007bd007d91529b76771f53/a0b58/ioBroker_ONVIF_1.webp 230w", "/en/static/2ee5ff9df007bd007d91529b76771f53/bc10c/ioBroker_ONVIF_1.webp 460w", "/en/static/2ee5ff9df007bd007d91529b76771f53/966d8/ioBroker_ONVIF_1.webp 920w", "/en/static/2ee5ff9df007bd007d91529b76771f53/cff29/ioBroker_ONVIF_1.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2ee5ff9df007bd007d91529b76771f53/81c8e/ioBroker_ONVIF_1.png 230w", "/en/static/2ee5ff9df007bd007d91529b76771f53/08a84/ioBroker_ONVIF_1.png 460w", "/en/static/2ee5ff9df007bd007d91529b76771f53/c0255/ioBroker_ONVIF_1.png 920w", "/en/static/2ee5ff9df007bd007d91529b76771f53/97bfd/ioBroker_ONVIF_1.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2ee5ff9df007bd007d91529b76771f53/c0255/ioBroker_ONVIF_1.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we can now add a new `}<strong parentName="p">{`ONVIF Instance`}</strong>{` and use the ONVIF user created earlier plus the local IP and ONVIF Port of your camera to add your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ef4362f5841d0a06a119113efe97637/1ac29/ioBroker_ONVIF_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEEUlEQVQ4y12T229UVRSH5xFFVOicmSmdS6dzOfcztzPnnDln7p1hpp0pRU3a2EIlxATFiA0gEG0UIWhivDzog8ZAsAG10qhPPvuffWafFgQfvqy1V7J/WXv91o7E8zrReIJ0rkC3P6DX6zPsDxgPTuHZddyajV93aNhOmNcF1f+wRbTr2ALHJZIryszOJUkkU9Q8n6DVptdfpN/t02t36LU6uHUH13Vo+gENz8eu1UOhJ2KWVaK/OMD1AyKG3SAlW5zMyWilMnq5gikwLSyBYaGrGoauh2dR1zQdXdMxdINiUWYwGHLn7ud0B0Mi0y/+ZvDlP9QufsXicMjkzGssn15lsrrK0vKEiWBpwvjUmNFozHhpmcl0hdF4ieXlKZPJlFa7w2A0RtFNImZjQEqrczKv0x8OWV45zWh5wnT1TBjH4yVWpisEfhDOqe56uA2fWt0JZ9bwA0rlCgVFJb2QI1LIJjn+8jGiMyeo2g7VuotulVEMi6JmUFA0CrKCrGioqoasauHl4mEu0HQDq1xhPlcgois5Xj0eJRZP0PJdFts+upyjVtJRC1lkuYhqWKiGiW6Y4WXlEFU30ETNMNBNi0zY4UKB6IyEFJVwplu0tm5iv36JYOMK9to2ZmsJWZGfCqiajvIMoiaiEE7PZ4moRQVpRiIuxTBHZ6m8+RHV9etU1q5RPbuD3jlNIZ8Pnyme/n/yRRlZVUPhVGaeiF5UkU5IJGIJnEqJRrWEW7ZwKxaeyB07NMHzAzz/IIqz32yFeavTwbCsUDSVyRCRs3liUoK4FMf3W7Q7i9RsF6tUpVS1scpVTKtMzXZotjv4QZOSqJXKIcIMMT/DtEim0kSkmVg4w3h8loppUjU0jGIBeSGLnM8i5+ZRFAXDLIWGCGOezFHVDud3WA8FY1KcGSEYi+ON1uiuv4e3cp7KeDOkOnkLsx5gmubBmijqAc+sjTgrqkYqnSEyE41x4tCU0uRtquduU93coXbuE6qbH1M7fwfV6ZLLzh8YIUx4IhoKHyC6fiooOhSdBq5DvxXQ9j3aDZdu0KDX9BksLjIYnqLb69PudMPf0Qia+M1muINC+KmgJMWRojES8VmCZpvhcEyn08f1fDzhbiMIcb0A2/HC36SLeRpWiBLO8WDBw7V54chRXjxylGMvvUJuIU8hXySfL5JOz5NMpskk58glE2TnEiwkZ8mlZilk5p4jl0mSSaeYm00Q2X+8z/1793nwYJdHv+yx9/s+v+095te9ff748y+u3v2e8to13M2bOBs3cDauP4e9cYNg8yrrF69RdAdELpy/yOb6Fme33uHyzjdc+fRbPrz1Ne/f+o53P/uRN3Z+xt5+iLO9i3flIY2rj0JE7m7vUru8S+uDn7h0+weM8QX+BQmOc9QtHOGZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ef4362f5841d0a06a119113efe97637/e4706/ioBroker_ONVIF_2.avif 230w", "/en/static/5ef4362f5841d0a06a119113efe97637/d1af7/ioBroker_ONVIF_2.avif 460w", "/en/static/5ef4362f5841d0a06a119113efe97637/7f308/ioBroker_ONVIF_2.avif 920w", "/en/static/5ef4362f5841d0a06a119113efe97637/5dd4b/ioBroker_ONVIF_2.avif 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ef4362f5841d0a06a119113efe97637/a0b58/ioBroker_ONVIF_2.webp 230w", "/en/static/5ef4362f5841d0a06a119113efe97637/bc10c/ioBroker_ONVIF_2.webp 460w", "/en/static/5ef4362f5841d0a06a119113efe97637/966d8/ioBroker_ONVIF_2.webp 920w", "/en/static/5ef4362f5841d0a06a119113efe97637/6c19f/ioBroker_ONVIF_2.webp 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ef4362f5841d0a06a119113efe97637/81c8e/ioBroker_ONVIF_2.png 230w", "/en/static/5ef4362f5841d0a06a119113efe97637/08a84/ioBroker_ONVIF_2.png 460w", "/en/static/5ef4362f5841d0a06a119113efe97637/c0255/ioBroker_ONVIF_2.png 920w", "/en/static/5ef4362f5841d0a06a119113efe97637/1ac29/ioBroker_ONVIF_2.png 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ef4362f5841d0a06a119113efe97637/c0255/ioBroker_ONVIF_2.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Object treee now list all your cameras video path, functionalities as well as an event channel that can be used to add your camera to your ioBroker dashboard:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a2687b2a40183d136e8388ff96814d5b/f4281/ioBroker_ONVIF_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAADhklEQVQ4y22U624cNRiG5wYimj0kIclmN7NzsD2e88Ezu8kmu0naQNoiJO6htOIHqNwC8IdyEkLlRhD/uLQHPEmaVuLHK3ss+Z3n9efPzq5esjOZMdzdI0ozrh4/YXO+5ubJNZvTFU1esmxMr65uMFVNY1U2NFVDXdbUVU1dN1SNwZl5AjcQjPcnuEFAZpYU5oSsaIjSmmW3QOucSCiKNCdNUkQokaFEBII8zSnygrKqKaoax3NnuO4xg+GAmeeT1i1JWRElKVJputpQZAWRVKRxSllWaB2TxClVWaFkRBwnJGmGiDROsbpGmTUTbZinBnN6TtMt+vgyiji7uibPCpRQRCoi0jGRTm7HSCOE7OPmZY0XCpxPvv+b6+/+YvXDPySfvWS5OicvK0IVEUaaz6+fE6vbjUoqpDWxUhoVxYRSsVieUJsWL5Q4RaFxfcFw94BpoGmWZ9TG9JtCHXOTtWQ6RbwjS5A6vlWcIFREYzqKusEXEqeMZkwPD/hoa4sjN6DpTkm0Igh8wsDHFyHhnYQIkXYeBr3st+/PaeqKIs+Ye3OcwA05nrpsP9pm6muKyy+ozj+lXj+luXhGsrwi6i7Qi8sPZNesVLvBXD6n2jwjSBucIs2YTWYMtrbwFzecvvqFm9e/c/3tWy5f/8n6mz/oXv5E+/LNg758w+LVz5x89SvNix+5/Po31q/fEj9/gbOMMtypy2gw4nD/gDzWbFYrThcLLtbnLLuWwPf6+PfyvTlaRxjT4HkuZWHvp+Z4NsMRexMmB0cMB2OOJlOStGJhC1M15EVFlpf9wduq28oKGfWVVTohznL8UFI2hjQvOJ57OKPRDuPhDsOhNTwiTmva5abvECkVoZAIqXqF9+O7tQgvEDSmJS9Kjt05zs54l/Fol4GNfDjtu8KaGdP1XXG/OZTyYS5kf31sd/ihoCirfu5awuFoBytrODmckGUVZ+vHbDYX5HlBGIr/JVQ67snSLMe03QPh9mDEcDS+I5yQpjltd0rbLvqetR0ie6P3oveEMXGa4gUBeVk+EO7t7TO2hNu3hllpWG+uaE2HUprQvipC9eoj3432ZzaB5wcfEu5/fNAbbj8a9IY6M5jFGUmcEASCOMmQtspCvmd+T63wg7A/Qxu9NxzayIMRuzt7TI9mLLolZ2drTk5WZFlOal+aO9J7QmsYR5oiSXtC+9pk9tr8Z/gvKNAt8GIX6dMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2687b2a40183d136e8388ff96814d5b/e4706/ioBroker_ONVIF_3.avif 230w", "/en/static/a2687b2a40183d136e8388ff96814d5b/d1af7/ioBroker_ONVIF_3.avif 460w", "/en/static/a2687b2a40183d136e8388ff96814d5b/7f308/ioBroker_ONVIF_3.avif 920w", "/en/static/a2687b2a40183d136e8388ff96814d5b/681e4/ioBroker_ONVIF_3.avif 1016w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a2687b2a40183d136e8388ff96814d5b/a0b58/ioBroker_ONVIF_3.webp 230w", "/en/static/a2687b2a40183d136e8388ff96814d5b/bc10c/ioBroker_ONVIF_3.webp 460w", "/en/static/a2687b2a40183d136e8388ff96814d5b/966d8/ioBroker_ONVIF_3.webp 920w", "/en/static/a2687b2a40183d136e8388ff96814d5b/adbc1/ioBroker_ONVIF_3.webp 1016w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2687b2a40183d136e8388ff96814d5b/81c8e/ioBroker_ONVIF_3.png 230w", "/en/static/a2687b2a40183d136e8388ff96814d5b/08a84/ioBroker_ONVIF_3.png 460w", "/en/static/a2687b2a40183d136e8388ff96814d5b/c0255/ioBroker_ONVIF_3.png 920w", "/en/static/a2687b2a40183d136e8388ff96814d5b/f4281/ioBroker_ONVIF_3.png 1016w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a2687b2a40183d136e8388ff96814d5b/c0255/ioBroker_ONVIF_3.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "control-your-camera-through-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#control-your-camera-through-mqtt",
        "aria-label": "control your camera through mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Control your Camera through MQTT`}</h2>
    <h3 {...{
      "id": "connect-your-mqtt-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connect-your-mqtt-broker",
        "aria-label": "connect your mqtt broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect your MQTT Broker`}</h3>
    <p>{`To get started either connect your camera to your already configured MQTT broker instance or activate the MQTT v5 broker on your camera and use the the MQTT client integration. In the following example I want ioBroker to be the MQTT broker for all my cameras on the nework. So let's start by installing the `}<strong parentName="p">{`MQTT Adapter`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8f878cebe1197b030bd22a7fb9769874/97bfd/ioBroker_mqtt_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABfElEQVQY0z2PXWvaABiFczOYo2ibmWYG0ZjvT2ObEFPWmyrDtuDolJao3eUUq4GOdrD9ht7sB+1qsN/1DCPbxYEXznkfzhHEtocoyVQrr3Bsi9FoxHA45PrqmsHFgDRNybKsVL/f/3//09lZRtpPy9zOF5zsA8H5Fa3oHCuMyfopSZIQRiGWbRPHMa7roekmtm1jeS6mH2HaLoYTomkGURJxmpzi+z7Czc/fjF9+MXj5Q+/jCtdQ6Z3EPN4/sllu2BYFm4cVT1/X3C9mFKtvrB+2fFkuKbZrnp+2TCafSli320XwfB/HcalLEs1mszR8zye/zJndzsjncz7nU+6KH1yMb1lMFszyOZPplPHlkOnmO+9HN9iGvm+oaTqGYXJ0dFgCwzAsp7W1NnJbRpb3OqgeIr6V6BgqUlOiXq9TqbzhdeWAhqKUf57nIfQCFctoU63tgUEQ4DgOmqbRMTs0Gg1qtRrvZJlWq4Vu6KiGiqIoiKKIfHxcZnftdsC/cdLB3VkZT88AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f878cebe1197b030bd22a7fb9769874/e4706/ioBroker_mqtt_1.avif 230w", "/en/static/8f878cebe1197b030bd22a7fb9769874/d1af7/ioBroker_mqtt_1.avif 460w", "/en/static/8f878cebe1197b030bd22a7fb9769874/7f308/ioBroker_mqtt_1.avif 920w", "/en/static/8f878cebe1197b030bd22a7fb9769874/006c2/ioBroker_mqtt_1.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8f878cebe1197b030bd22a7fb9769874/a0b58/ioBroker_mqtt_1.webp 230w", "/en/static/8f878cebe1197b030bd22a7fb9769874/bc10c/ioBroker_mqtt_1.webp 460w", "/en/static/8f878cebe1197b030bd22a7fb9769874/966d8/ioBroker_mqtt_1.webp 920w", "/en/static/8f878cebe1197b030bd22a7fb9769874/cff29/ioBroker_mqtt_1.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f878cebe1197b030bd22a7fb9769874/81c8e/ioBroker_mqtt_1.png 230w", "/en/static/8f878cebe1197b030bd22a7fb9769874/08a84/ioBroker_mqtt_1.png 460w", "/en/static/8f878cebe1197b030bd22a7fb9769874/c0255/ioBroker_mqtt_1.png 920w", "/en/static/8f878cebe1197b030bd22a7fb9769874/97bfd/ioBroker_mqtt_1.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8f878cebe1197b030bd22a7fb9769874/c0255/ioBroker_mqtt_1.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure the broker according to your needs with a network interface, MQTT port and broker login. This login has to be later used in your camera configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f122accc206d8d82b8928d373a715928/97bfd/ioBroker_mqtt_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACS0lEQVQoz42S204TURSG59Yo7dTUCxSmM9PjMOdT59ADlFZMkUMpJwPhikICwnNwbYzPZLzwmT4z04qoMfHiy17/+tde2WvvLZQbbcpvFEqiyJquM9oaMRwOGY/HbGxsEIYhcRw/EkXRb/onSZLktcKb5WUqK68RXzyjLqtc7c4YDjfzphmDwWDeyPdJk4ROp0MQBH/heR6TyQTB6b7D7o9ZNVOqmst6Zxs3jEnSlLTTpR1FJElKux1h2w6O42LZTk6mbcfFMExmV9c8PDwgHH35xsHnr4w+fced3mHpNWzbRmtU0eoKrZqCVlcfyfSfNKsyoWuSxBGCKivUVAVx6TmrksT66Ue6J7eEk2vaR3fExxn3xCf3RMd3BNMb2oe3hE/ItDe5oeUmCLYuU69VKBREpIpMf/cD3mCX4O2UZOccf+sYd3SIM5wSvz/LyWJvdJjnvdw7INo+I+ptItTVFRR5lUKhSEWS6HcSumlMGof4jomltzBajRzb0HBNfa61X+itOq6lEwYBgqyoSJUKoigiSRJx6Odmtrn5j/t7mstpVOdes4GQPYCiKBQKS/nI0fgUa7CPNZjgj8//n+0LzDBFcCWVmqpSLBaRZIVw5wJ3URDuzwgnVwT7M/y9y1wHT9m7XMSXBJNrrHYXoVwq8apcplgUkeUKw/UuRqueozdr6I0qltYgCV08ay3/ItpibL1ZXcRKvq5pLYTSyxKlUgkxO6Ek0e31sB0H07QwLRvDNPF8n16vn3/iLJd5nufTjmK0NR3dMOboOj8AY/+E6nwNymcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f122accc206d8d82b8928d373a715928/e4706/ioBroker_mqtt_2.avif 230w", "/en/static/f122accc206d8d82b8928d373a715928/d1af7/ioBroker_mqtt_2.avif 460w", "/en/static/f122accc206d8d82b8928d373a715928/7f308/ioBroker_mqtt_2.avif 920w", "/en/static/f122accc206d8d82b8928d373a715928/006c2/ioBroker_mqtt_2.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f122accc206d8d82b8928d373a715928/a0b58/ioBroker_mqtt_2.webp 230w", "/en/static/f122accc206d8d82b8928d373a715928/bc10c/ioBroker_mqtt_2.webp 460w", "/en/static/f122accc206d8d82b8928d373a715928/966d8/ioBroker_mqtt_2.webp 920w", "/en/static/f122accc206d8d82b8928d373a715928/cff29/ioBroker_mqtt_2.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f122accc206d8d82b8928d373a715928/81c8e/ioBroker_mqtt_2.png 230w", "/en/static/f122accc206d8d82b8928d373a715928/08a84/ioBroker_mqtt_2.png 460w", "/en/static/f122accc206d8d82b8928d373a715928/c0255/ioBroker_mqtt_2.png 920w", "/en/static/f122accc206d8d82b8928d373a715928/97bfd/ioBroker_mqtt_2.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f122accc206d8d82b8928d373a715928/c0255/ioBroker_mqtt_2.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a39fa82bc690f16488faaa5c0589bd02/97bfd/ioBroker_mqtt_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABS0lEQVQoz4WR20rDQBiEF0EEwdaKpdra1NQeyPm02yQNhZhYaUEsXoU+QOuT9EYQfCJB8L1G/q0JxRsvPn42zD+zk2WXI4HmjYpGowFdNzB/nCPPcyyWS6RpCiEEoihCGIb/QlrWaTXRa7dQPz3GoKdi+/yKPMskD3mOLMv2hpxjGsdIkgScc7n8dxZFAcYXBfhijf70CUM7RB6nCLiALaZwJgm8QCD6TbcsS2KaZgWddV3HZrPBbrcDe/n4wur9E/dv33BXW7jmCAGfwOcT8DCGZTvQdQ2apsG2bWkcBIG8VTkdx4HruvB9H+y2c41+t42zkyN021fIsn1NIYU2LMuEYRgSMqRFwvO8yoi+UyDBXFPFcNBD7fwCXUWRqQSJqEppRvVojsfjavmQUssGdwpUVUGtVoeiKPJlZ7NZZVxWozqHYeX/PIQMfwBPy99N+6RWOgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a39fa82bc690f16488faaa5c0589bd02/e4706/ioBroker_mqtt_3.avif 230w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/d1af7/ioBroker_mqtt_3.avif 460w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/7f308/ioBroker_mqtt_3.avif 920w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/006c2/ioBroker_mqtt_3.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a39fa82bc690f16488faaa5c0589bd02/a0b58/ioBroker_mqtt_3.webp 230w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/bc10c/ioBroker_mqtt_3.webp 460w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/966d8/ioBroker_mqtt_3.webp 920w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/cff29/ioBroker_mqtt_3.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a39fa82bc690f16488faaa5c0589bd02/81c8e/ioBroker_mqtt_3.png 230w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/08a84/ioBroker_mqtt_3.png 460w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/c0255/ioBroker_mqtt_3.png 920w", "/en/static/a39fa82bc690f16488faaa5c0589bd02/97bfd/ioBroker_mqtt_3.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a39fa82bc690f16488faaa5c0589bd02/c0255/ioBroker_mqtt_3.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Note`}</strong>{` that setting ioBroker to publish it's own state when a client get's connected might result in wanted or unwanted behaviour. E.g. if you change the configuration of your camera through it's web user interface or using a mobile app while the connection to the MQTT broker is dropped those changes might be overwritten once the MQTT connection is re-established. On the other hand, if you make changes to your camera configuration through ioBroker while the MQTT connection is dropped this option makes sure that these changes are not lost:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd558075dfd9348280946c4453b72b34/97bfd/ioBroker_mqtt_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC70lEQVQ4y3WTOW8TQRiGt+IQyFcMEXYSOfjYY2Zv784ePhLbIcSxZIIU0aWi4WeQCikSBQUNgigNXej4CyAKpFT0Af7Hi77JOrEDFI9md/abd97vWKWsCSzdW0axUIDruRhuDTEajrCzs4N+v48wDBHHMeI4QRTHiKIoe18kSRIIIaCs1BpYXV1FvpCDxz0cTA6wOdjEaDTCYDCQokKEEL6POIrkwXa7vUAQBLAsS8Yqmqai0VJRKJXAVY79rX2ISKDb7aLX6yFKUvhRCj+I4HkefN+H4ziXuK4Lzjn29vZweHgI5dm7M0zffsfozRn44Ck0tQZuWjKIMQaDm2BOG4btQdcN6LoOwzAWUFVVGphMJlC42ITup7j/kKFlcGxubEjrlIrBmBTmtDJ2ccE1MYL2W60W6vU6FN5awWplGXdv30SzUceT6VQKUioXablXKdo2TLrgGqZpyhrSqnhWDdVqBbl8HvWmirS3ARGGsrsE1czzffieh0AkcPwAViYwg2XuCWVtrYbqygryuRzWmxrCtI8waCMUQqZNQVQ3XVPBHB9h3EUkhBwfghpF32flULhlolqtIpfLodGoy9GgeSJ3s0OUimEwuI6NiEZICMlsXBYcBjqXc0iCVNQ4SaSIyBxSykEYymCql5hzR5fSXNq2LV1qmgZlrbyMB5WKFKT2dzod2RBKZdZBWkmMhp2+0yWzyyhu1pherw8lXyyiWCwin8+j2WzK32jmhoLmCUMh3fyvy2REKZVKIEiw0WjIupDgvBAdIqE0TWVa/xpugvb/Eux0u5e/E5eCViZqwnFdBEGYOXXk4DPGF7gULBQKWF+voZMmiCMBx7ZgMgNM18ANHczQYJtcYnEm93StBUNTJXrGnMMcWjrHo90phttjjB5P0BlsI0g3YAfJJVYGPTthCjvDlcQXgkvlMu7cuoH+85c4Ov2KVyef8fr0C8bHPzE5+Y3xh3OMj8/l+8V6xe7xOYbvf+HFxx84+vQNfwAi7COPIbguGwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd558075dfd9348280946c4453b72b34/e4706/ioBroker_mqtt_4.avif 230w", "/en/static/bd558075dfd9348280946c4453b72b34/d1af7/ioBroker_mqtt_4.avif 460w", "/en/static/bd558075dfd9348280946c4453b72b34/7f308/ioBroker_mqtt_4.avif 920w", "/en/static/bd558075dfd9348280946c4453b72b34/006c2/ioBroker_mqtt_4.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd558075dfd9348280946c4453b72b34/a0b58/ioBroker_mqtt_4.webp 230w", "/en/static/bd558075dfd9348280946c4453b72b34/bc10c/ioBroker_mqtt_4.webp 460w", "/en/static/bd558075dfd9348280946c4453b72b34/966d8/ioBroker_mqtt_4.webp 920w", "/en/static/bd558075dfd9348280946c4453b72b34/cff29/ioBroker_mqtt_4.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd558075dfd9348280946c4453b72b34/81c8e/ioBroker_mqtt_4.png 230w", "/en/static/bd558075dfd9348280946c4453b72b34/08a84/ioBroker_mqtt_4.png 460w", "/en/static/bd558075dfd9348280946c4453b72b34/c0255/ioBroker_mqtt_4.png 920w", "/en/static/bd558075dfd9348280946c4453b72b34/97bfd/ioBroker_mqtt_4.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd558075dfd9348280946c4453b72b34/c0255/ioBroker_mqtt_4.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you saved your settings you will see a yellow warning icon next to the MQTT adapter showing you that it is running but no clients are connected:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/91f47cf1419d04894d5a306c1aae380f/97bfd/ioBroker_mqtt_5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsElEQVQoz2XQS2sTYRTG8dmoC2lMRaXkQiYxmWuaeTP3dDLJvJOZXJo0KbUo4kaXfhRX4jcSxM/1l4ykULr4ceB54Bw4yhst4G2ry/l5HcuyWa1WFEXBZrMhkxlhGBJPYuL4vzAKieLowSk/UZoX7+g0L6i/fIHabhEHEc7lCOEIXOFiGRaGZqBrOqZhIkYC27SxLbuax/7k2Cve8h5v9RE1XGNGMcl1RlymTIoJ3sxjnI5JlglJmVTZ0Xg6xgos7NDG9M0Hx0y5+/WHw8/f5D/+4nz9zs3aZbWbEtxFGLmBURpkXzL8vU9wCDAWBlquoef6E/bSRlFbDXqdJrXnz2iZDcrdhGnp4x9CrNyqFjgbh2ExRGwE3tbDzE10qWNIo5on1sJCEZddtEGH2tlresMGxT7lapviXI/oz/vohc7s85xhaTNajxDXojoyyAZoUnvELEyUntpE7bSpnb2ip/coPm3Jb5ckyysCGRAVEXIvyW9y5E4yKSdVHubhE8f/Ku/7A9Rul3qtTkdrs/t2j/ywxl+4ODMHkY9J9ynyIMkOGUER4MwdRCZwMucRN3f5B+BtAoSci2YyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91f47cf1419d04894d5a306c1aae380f/e4706/ioBroker_mqtt_5.avif 230w", "/en/static/91f47cf1419d04894d5a306c1aae380f/d1af7/ioBroker_mqtt_5.avif 460w", "/en/static/91f47cf1419d04894d5a306c1aae380f/7f308/ioBroker_mqtt_5.avif 920w", "/en/static/91f47cf1419d04894d5a306c1aae380f/006c2/ioBroker_mqtt_5.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/91f47cf1419d04894d5a306c1aae380f/a0b58/ioBroker_mqtt_5.webp 230w", "/en/static/91f47cf1419d04894d5a306c1aae380f/bc10c/ioBroker_mqtt_5.webp 460w", "/en/static/91f47cf1419d04894d5a306c1aae380f/966d8/ioBroker_mqtt_5.webp 920w", "/en/static/91f47cf1419d04894d5a306c1aae380f/cff29/ioBroker_mqtt_5.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91f47cf1419d04894d5a306c1aae380f/81c8e/ioBroker_mqtt_5.png 230w", "/en/static/91f47cf1419d04894d5a306c1aae380f/08a84/ioBroker_mqtt_5.png 460w", "/en/static/91f47cf1419d04894d5a306c1aae380f/c0255/ioBroker_mqtt_5.png 920w", "/en/static/91f47cf1419d04894d5a306c1aae380f/97bfd/ioBroker_mqtt_5.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/91f47cf1419d04894d5a306c1aae380f/c0255/ioBroker_mqtt_5.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`So let's add our mqtt broker configuration to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT Menu`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/97bfd/ioBroker_mqtt_6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC0ElEQVQ4y3WTS4+aYBiF/Q8TAZE7KlWpOHJRVECuijqJzbSbpumm6aq7dtFFm3TWXfQvn+b7uDhO08UJHwSenPecl44+HELRdCj6AIqqQjZ9iPYRvVmG3iz/jzJIixLcqxVsx8VyuQLDshAEAR11MGiBkiRgEr+H9/E3zLdPmDw+YUr09oUefyH49AfW+QtGhoHZzALX60EQRXQojAJ19CUZc8dBcTwiLQpESYooTREmya3iGNv8jPvVFizThSTJ0HUdqqqio+pXhxXQQ1aekB5Kes3LE6KsQJBkCNOcnsk1Lkq4/hoMw0BRFAyHQ2iahg5xRoCyptVAF7t8jyAhHx2QHo71fYY43yMpDhQYpgUFsiwLURQhSVKdYe1QVivgveOhOJ0RF3uEaUZBRKttAM/3YXse5raDhevB3wZwXRemadJx+/3+NcMW6FbArDwi2ZftiH4QwKlh1mKBe8eFtbBhGAbCMKRQkuPVoabfZEjGbdwFaY4gTrEJd/CDkIo4Tos9LpcLoiiCZVnQGqBKGtIHEGSldvhAy0j3ZQuN0oy2vg4j+EGEdbjDdhdTZyRDnufR7XbRkVQNWr2LglyNnB8rWFMGUbI/0GckW6KsPMPzN+gyDFiuB5bj6LlDsqtcPnd4ptDscKzWhSgrsMsLBPkZQV7S9r31FhzfvxF1SH87/Zphfjw/W49KNEeaZ0FXirh3/Q3YHt/CyJkCibumlGrkcztuWC/0OgjheEvMbbtdm4W3bEEt8N+W3WpdGke1SAGkXWflU3nrDezl6tZhM3IjXlLgeTa+XSb4+UbD5RAiyE9I8hz+6QPsd98RPXzG13WIH9sE6WqN7ssMRUUFEQEKiorReIy5ZWJmGhhPpzAmZqXpDCPzHsbUwuvxBNbExHBkgOnx1GWjTgNrobIClhfR5ZqXenQtugxL14LojmVxx3Lg+iL6kkK/6YsyeFHGX/2IFx1nd1hlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/e4706/ioBroker_mqtt_6.avif 230w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/d1af7/ioBroker_mqtt_6.avif 460w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/7f308/ioBroker_mqtt_6.avif 920w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/006c2/ioBroker_mqtt_6.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/a0b58/ioBroker_mqtt_6.webp 230w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/bc10c/ioBroker_mqtt_6.webp 460w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/966d8/ioBroker_mqtt_6.webp 920w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/cff29/ioBroker_mqtt_6.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/81c8e/ioBroker_mqtt_6.png 230w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/08a84/ioBroker_mqtt_6.png 460w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/c0255/ioBroker_mqtt_6.png 920w", "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/97bfd/ioBroker_mqtt_6.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d52ad32bc371d1d586d6f7ab31e4c1d3/c0255/ioBroker_mqtt_6.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/da30c48ce60803024300085fac94bff4/97bfd/ioBroker_mqtt_7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACP0lEQVQoz22SyW4TQRRF/RGJ23NPnnqy22N77rk9YRQSpAQEi0iIJQtWhEGs+O6DuiwHkFhcqVRSnbr3vVvQmi0UvYmqqSjGCM1/T23+QHV2/19VvHvkxQP65h317pA0iZEVlUq1Sr3RoKDoOrLWpC43aLlLZm9/Mnj9ld7tF3q3T/+of/eE8+qJyf13/MdfaL0FtmWgajq1Wo1KpUpB0XRyaE1WaBsG6TZju98SJRFhEhFEIX4YsAl9NoGPH/isg5BlckDXdUqlEpqmiXOtXj87zCPXc6BpEW33QvHuQLw7EmY71lGKn2wJ0h1+umUTZwTZDr3VRpIkAWs2mzRk+RI5dyjTMS3WUcIqjPGTjDDbCuV3fpwS55/lH4QxmzilYxiUJAlFUajVqpQrlXNkWdXOkU2LZHcg3R8FcBUl4uEyiBhNpvQGQyHHHTDyZswWC8ajEd1uV0DL5QtQ05+BsYi7F1EDES9lFUSMPY/BeIw7GtEfDJnOF7Q6HabTKZ7nYRjG31v+A0z2R7LjSYBz2NlhyGgywXFd7L6L1esxmEx5cXrJ6XQSUMdxRHUKat5BvUlDUQUwSDKC5AzKZ3eZ6Xzt4602zFZrZssVi02AN5+LDeeVkYpFrovFMzB3mW+5ZTp8OPb5caPyeHBZJ3vCJGWdnZjcfGJw95k3/p5v3pKP6xCt3eHq6losoyhJXBelS210GqqK2mzj9h1cp4Ntm3Qth65l07Ec2vaAtjPEMm36holj2tQVFalcoVSpPus3171fTRbr5d8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da30c48ce60803024300085fac94bff4/e4706/ioBroker_mqtt_7.avif 230w", "/en/static/da30c48ce60803024300085fac94bff4/d1af7/ioBroker_mqtt_7.avif 460w", "/en/static/da30c48ce60803024300085fac94bff4/7f308/ioBroker_mqtt_7.avif 920w", "/en/static/da30c48ce60803024300085fac94bff4/006c2/ioBroker_mqtt_7.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/da30c48ce60803024300085fac94bff4/a0b58/ioBroker_mqtt_7.webp 230w", "/en/static/da30c48ce60803024300085fac94bff4/bc10c/ioBroker_mqtt_7.webp 460w", "/en/static/da30c48ce60803024300085fac94bff4/966d8/ioBroker_mqtt_7.webp 920w", "/en/static/da30c48ce60803024300085fac94bff4/cff29/ioBroker_mqtt_7.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da30c48ce60803024300085fac94bff4/81c8e/ioBroker_mqtt_7.png 230w", "/en/static/da30c48ce60803024300085fac94bff4/08a84/ioBroker_mqtt_7.png 460w", "/en/static/da30c48ce60803024300085fac94bff4/c0255/ioBroker_mqtt_7.png 920w", "/en/static/da30c48ce60803024300085fac94bff4/97bfd/ioBroker_mqtt_7.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/da30c48ce60803024300085fac94bff4/c0255/ioBroker_mqtt_7.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in ioBroker the warning is now gone and our camera connected:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f77de69605bb1ad98d6a2c78875a15f/97bfd/ioBroker_mqtt_8a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVQoz23NzW7TQBiFYS8RomqKEKqwHKemztgeJ/HPOI4nDbFTp3EUpJCWLhBiVbHlXtgg7gmx4IpeZEtUKu3i0YzOmfk+403fwerbvDzp4Zw56FlBmqRkKkOlChlIAj/otPdROCKU4f35P2MoPLwg5NXrU86H5xSX71AXOdN5hioUURaRFimZzlBaMS/nxHmMjGXHn/gPGLc/f3P94xfr739I7r7R7HLW+4r8MCO4DJBXkvpLjT5oikPRZf7KR5QCUT0ka4nhJxqZak77Hrby2TaKZZ2QfcgJ2o8rQbSLusdRExFto0eD/mmXGYFr8vbM5OjZcyxpUt2sudgtmOzGuKWLqAX6VhNehUy2E5L3CX7td92wGuJW7r02N/yhheNYHL04xpEOy8OKxW5JvsmJyoikTqiuK4qmoNgWqFoRV/GT2s4YDBzswYDecY+B2+fw9TPNpz2zOkdViul6SrkvaT42bG426I3u8myVPTJbzzBs28Y0zW6g5VikC4VUI4I0QEQCL/YY52Okkh0v8RCxeFLb/QWcf/+u0mJzJAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f77de69605bb1ad98d6a2c78875a15f/e4706/ioBroker_mqtt_8a.avif 230w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/d1af7/ioBroker_mqtt_8a.avif 460w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/7f308/ioBroker_mqtt_8a.avif 920w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/006c2/ioBroker_mqtt_8a.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f77de69605bb1ad98d6a2c78875a15f/a0b58/ioBroker_mqtt_8a.webp 230w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/bc10c/ioBroker_mqtt_8a.webp 460w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/966d8/ioBroker_mqtt_8a.webp 920w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/cff29/ioBroker_mqtt_8a.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f77de69605bb1ad98d6a2c78875a15f/81c8e/ioBroker_mqtt_8a.png 230w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/08a84/ioBroker_mqtt_8a.png 460w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/c0255/ioBroker_mqtt_8a.png 920w", "/en/static/2f77de69605bb1ad98d6a2c78875a15f/97bfd/ioBroker_mqtt_8a.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f77de69605bb1ad98d6a2c78875a15f/c0255/ioBroker_mqtt_8a.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you connected a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT debugging tool`}</a>{` to your broker you will also see that all camera status topics are now being populated giving you access to the entire camera state:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4ae39b870f25ed9f9132b173f5f036e7/3dde1/ioBroker_mqtt_8b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACQElEQVQoz2VSyW7bMBT0N6SxZDdpvWgzJdmS3aaqASdaqIXarCStc+ilh35Egd6DfEHQQ44BeuhPTvHo2ECRw2DIB77hzCN7vr+D533BfH4D06xhmg1s1oLNXsOyGuh6BV2vX1DBMGqYRi2Z9r13RoOx1UJjW2h2J9eG20nQXnOItxJTtsV7c4uR1WFk7deTWQfdvYFmX2PCrtEbqCr6AxUnQwWqomAwUDFQB1AU5QhVVSWGwyEMQ5fQ9T2MF9Y0DbbN0HujKvDONdzqH+F/+oCl52Oz2aAoCuR5LjmOY0RRdOQwDI+4vLyUNcuypHDvpH+KC2eBH81XZKWAKIQUKcsSVVWiaWrkeYY0TWW9bVspnCQJOOdomkbybMZgGAZ6/dM+mOsgqwR4kiBNOcqyQSFahBFHGHLEcYYoSlDXNXa7HYQQ0hUluLu7kxcdHdKMJuMJ5u4cjutisVi8uBHgPEVeCCQ8RRzvHR1ALkmQ1nT+P8HpdIrVagXf97FcLpFlmYxMDV3XoSyFbKJmipryVKKqqmOdsdk+8kGQhDzPk0yHSEwOPoqOD0KNFDchh0mCOIrkBUIUYLYL07ReC5JLmgkNm0QPL02u6SISzKIEWcLlSDjPZAp7HsOwVntB+kMUmURphjT45+dnPDw84P7+Hk9PT3h8fERDL3wV4k/wHX/tHbbVTzTffiNvf2E0GmE8mewFaeM4DhhjsG0b6/Vaxjv8NYpFkakeBAH4xRp89RlBsEGwjhCsr3B2PsTZ+Vv8Az3bkD9RV8XjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ae39b870f25ed9f9132b173f5f036e7/e4706/ioBroker_mqtt_8b.avif 230w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/d1af7/ioBroker_mqtt_8b.avif 460w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/7f308/ioBroker_mqtt_8b.avif 920w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/7a54e/ioBroker_mqtt_8b.avif 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4ae39b870f25ed9f9132b173f5f036e7/a0b58/ioBroker_mqtt_8b.webp 230w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/bc10c/ioBroker_mqtt_8b.webp 460w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/966d8/ioBroker_mqtt_8b.webp 920w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/5304f/ioBroker_mqtt_8b.webp 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ae39b870f25ed9f9132b173f5f036e7/81c8e/ioBroker_mqtt_8b.png 230w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/08a84/ioBroker_mqtt_8b.png 460w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/c0255/ioBroker_mqtt_8b.png 920w", "/en/static/4ae39b870f25ed9f9132b173f5f036e7/3dde1/ioBroker_mqtt_8b.png 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4ae39b870f25ed9f9132b173f5f036e7/c0255/ioBroker_mqtt_8b.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/78b56edb376ab4565b80291da14b26cf/b12f7/ioBroker_mqtt_8c.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABbklEQVQoz12P227TUBBF/TMtl8SXxhF2Hds5xz62Y5/Epi0pUi/iiQf+gUZQKQgB4rUIaNN+6UInpRb0YWk0o5m991jZYoPq7igPbpHza4S+Ri/vqI9uqQ439xzck7c3BPkPgvyKsbpiv/iJaH4TV79oljcs32yw5sdfWBx/RrUfUe0Hkuo9arEin6+QzQVSXyD+VqlX5O0a/eobp81XpL5kdrim6NYsXn/i9O13rNmsoig1jv0Ex37KcLDD4PkOw8Eu9gPD3e3cc59RqJSjlw1nqkRO9xHTCCkikjikqUusF0HKydk7lCpI0ylSZgRBiOft/Yfr7THyx2SxQMUCOZUUaYYQcntjbrWeY43HPufnJ3Rdh1KKqqqI4xjXdfE8r8f0o9EIIQRpmpLE8XbPkCQJk8mEuq6xfN9HFQVRFG2HYRj2Ao+rETSGZVkipSTLsh5j1LYtllm0bRvHcXoei/0raL7I87xP9oAJZBL+ATJu/MABN3pOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/78b56edb376ab4565b80291da14b26cf/e4706/ioBroker_mqtt_8c.avif 230w", "/en/static/78b56edb376ab4565b80291da14b26cf/d1af7/ioBroker_mqtt_8c.avif 460w", "/en/static/78b56edb376ab4565b80291da14b26cf/7f308/ioBroker_mqtt_8c.avif 920w", "/en/static/78b56edb376ab4565b80291da14b26cf/2c8af/ioBroker_mqtt_8c.avif 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/78b56edb376ab4565b80291da14b26cf/a0b58/ioBroker_mqtt_8c.webp 230w", "/en/static/78b56edb376ab4565b80291da14b26cf/bc10c/ioBroker_mqtt_8c.webp 460w", "/en/static/78b56edb376ab4565b80291da14b26cf/966d8/ioBroker_mqtt_8c.webp 920w", "/en/static/78b56edb376ab4565b80291da14b26cf/52c2b/ioBroker_mqtt_8c.webp 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/78b56edb376ab4565b80291da14b26cf/81c8e/ioBroker_mqtt_8c.png 230w", "/en/static/78b56edb376ab4565b80291da14b26cf/08a84/ioBroker_mqtt_8c.png 460w", "/en/static/78b56edb376ab4565b80291da14b26cf/c0255/ioBroker_mqtt_8c.png 920w", "/en/static/78b56edb376ab4565b80291da14b26cf/b12f7/ioBroker_mqtt_8c.png 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/78b56edb376ab4565b80291da14b26cf/c0255/ioBroker_mqtt_8c.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For example, you can use the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WQHD_with_ioBroker_MQTT/"
      }}>{`MQTT Alarmserver`}</a>{` test function (or simply walk in front of your camera to trigger an alert):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/97bfd/ioBroker_mqtt_9a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACn0lEQVQ4y3WT3W7aQBSEeYS2AmN7vf82kAIhSiAQINgGjElSiVaJVLU3rdRKfYLmulIffKpd/5Qg5WJ01ivvtzPn2A0dRRBKQ+gQQgqI4Rx88gHkYgdykb+iHfj4Ad7ZHFfjCabTGZx2G0EQoCHDsAYyThEtnzB4+gN9/wx5/wx1omLvN66+/EUv+4FOt4vz8xFcz0NAKRoWVooKCSYEwk5kpUINFYa1tFFkqoaIevAZR9txwBiD1hpSSjSkDl8AjdNFkiLJciTbHeIjLdNNrdUmg+500Wo2IYRAFEVQSqEhdAHjUoFyUQDXOeL9AWmWI93trcwFBlIpznKE3R4cxwGl1Loselg6ZMfAZI14m+N2vX0BOdX7wRDj8RWGw6GNSwj538NToIn7GtDsG8fmXRM1jmMMBgPbx9ohPwGmZURz+FRm3/TxcnKNw+GAOEkwGo2gKqA0EzKfTTmU23SDbH+Hte1fXqropb2ohPbPR+j3+2CUwvd9tFotNExUVX6LZsoGPEtzzHafsNzeWYCZsB3EJsNiu8PUOMxyO+VmqwXX9dB2XbQcBw0T1cbW2jrkuoP9ZoHvD2N83k9qmI2/3eHjKsW3+QqPyQaqd4a268H1SS3rUFSTFgLMAFeX+LXv4ms2wHyVYB6nts7iFB9nC/yc3ODx5hai062Bbc+3skDj0Dg1DpmUFurKHjzRsdM3ChiHTwiahOBtWQllNayqL6dsfz0JoRSEKn5Ds2fFhQX4JKhFGC9BRdx2FZmV7qhQEILjOnqHZfcNOooikMa9BAkHaPduQKMLTAnFImBQlMM56p/tYeWgAto1p6CBDz8I4AUUnq0MHuW2BiQAJQG8o97VPbSAMm619s3Bo2hmXRzwrByz9ol9jzCBwLaD2+d/ipb/ZsJECO4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/e4706/ioBroker_mqtt_9a.avif 230w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/d1af7/ioBroker_mqtt_9a.avif 460w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/7f308/ioBroker_mqtt_9a.avif 920w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/006c2/ioBroker_mqtt_9a.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/a0b58/ioBroker_mqtt_9a.webp 230w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/bc10c/ioBroker_mqtt_9a.webp 460w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/966d8/ioBroker_mqtt_9a.webp 920w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/cff29/ioBroker_mqtt_9a.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/81c8e/ioBroker_mqtt_9a.png 230w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/08a84/ioBroker_mqtt_9a.png 460w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/c0255/ioBroker_mqtt_9a.png 920w", "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/97bfd/ioBroker_mqtt_9a.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/24391b1c2e5fce8ebc6c87639a53bd6f/c0255/ioBroker_mqtt_9a.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/"
      }}>{`Alarm Server topic`}</a>{` will be updated with the alarm payload:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/eb3fa/ioBroker_mqtt_9b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDElEQVQ4y5WR207bQBRF/TNt1UDujkRiz9gzdnyNL8QhhfLASx+Q+gf9gSIaWlUtKEIVv7oq24QGiT7wsHRGo6N99j7HsLwNtr9BBjcI/xuW/oaf/sBLvqPjf6joBjnfMBFXDab4iimvsPQ1R+41frphUd1gpKd/yM8fSE+3xCd3uPlPkvUt8fqWoPr1jLD6jSru8Jdb4uN74mJL/GFLWG2pLu65+PyAEQaKMND0ex163fccdN7Q6bzl8ODdE93DttY9wdylKBPOy5xSK4ScolwLYU+JwgDDNCdU1RKtXWzbxnUV0+mMwWDAYDDcY8BoPMaTilBqfFczdz1C1yNxPLTjkqQLjOFwyNnHT5TLC4RUCGEjhGgE+v3+o3D7rnullCi3HS5s+6la1owwjDB6vR5ZcUaSnWOaUyYTk9Fo9CS2X+v/MAzJ8hylNUqptmqN4zgsFhlG3bxaLTkuM3zfI0mSZmo9aOdu32GapmTZAq0VWusGz/NYr9esVidt5CiKkNLBsu0m0r7DfcHxeIxSDkI4zCzJbHbUYk2b/7I8xqgbu91u42jHfsznkcfM5x6+HyPkHNu2mjT24w6jKG4jv8RLDus0zd6U24jVx9tRJxNC/l/wpQG7o9Tsdlez26XS3usF630XRdFQlgVZljei9UH1awXryEEQNJeO4oQwyrm8vGRRfSFZXeP6p/wFZ0jGn2HyV/oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/e4706/ioBroker_mqtt_9b.avif 230w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/d1af7/ioBroker_mqtt_9b.avif 460w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/7f308/ioBroker_mqtt_9b.avif 920w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/b248f/ioBroker_mqtt_9b.avif 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/a0b58/ioBroker_mqtt_9b.webp 230w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/bc10c/ioBroker_mqtt_9b.webp 460w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/966d8/ioBroker_mqtt_9b.webp 920w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/1dc52/ioBroker_mqtt_9b.webp 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/81c8e/ioBroker_mqtt_9b.png 230w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/08a84/ioBroker_mqtt_9b.png 460w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/c0255/ioBroker_mqtt_9b.png 920w", "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/eb3fa/ioBroker_mqtt_9b.png 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8b6ca23d7bb6a86e33967c8dd209d11b/c0255/ioBroker_mqtt_9b.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in ioBroker you can see that all status topics are also registered inside the object tree and the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WQHD_with_ioBroker_MQTT/"
      }}>{`MQTT Alarmserver Topic`}</a>{` also registers every incoming alarm - ready to be used inside an automation:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9260a6289470aefc0fc0b88dfc21acc8/97bfd/ioBroker_mqtt_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACp0lEQVQ4y5WUy24bNxSGtS2aVpcoDeRa99ieITkXzmhGmqukSKndCgmCLrrJIg+QAmkW2RToOxTZuAG68BslfaKvIO0ItVsb6eLHIYjhd37ynDONrjPn/oNvaLdaRFHM2ekp2+2W3e4H1qsVaZpSFDl5npNlmY1FUVyLZt9osVjQ6I+P6A+GdDodTo6PSfKavKzQac5sUZAVJUL5+L7PbDbD8zxc10VKaWMYhnY/jmObvHFyNGUyHtFqfs10OkXHCVpHKD8k1DGzJCEINXEc2cNRFKG1RillQWZtkgVBYNUIooRj4fGgd8gjN2CRVwjXQUmJEC6+9Hnx5AXKUwghrEMDMA4/RSNzdcdxaPx4/pHn5x94/O4vvO1PBIEmSVOkvAQooch0Zte3ybg172fWDZ1ETFyP7uGE8WRKmJQsN2eEgY8rhM3uuM7eyU19Aq5WK/umjVAc0HvY5at7XzIa9FFhzKJ6TLZI7cf7g1Igxe1AU2ULHAzGfHt4SKvVZjQc4OmErN5QFvn+2re5+yewLMtLoPI9Dg4OaDZbV8CUerujLDLEHaCbwLquL4GJq+j3+zSbTUajEcrz0Wlh+0qIzwBKub+yLcqg+5Ber0erZRwOka6DXqxY1jVKuLaF7pRjWkxQVyWuc0Kj2W7TbrctcDw9wtMzovp7qs0pwWyODGNUOLtDMX6UUK2fIIOIhhm5zv0uzXtfIM9esnx7QfXqd57/dsHm7Z+Ur/+gevOe6pd/a/n63Mb6zXt2v15Q/PzuCtjpXDqcPELphKTcsHv6jDBOkb5GBdF/ygs0MtD4Oma9/Q7hhdeBpsqmKNG8tG3jKXn1Vu4dMk0vWMznuGb0rgFHQ/tTiLMl6/Xazurn9aFHVVW4rrjpcIj0Q+bLM8qy2I/e/+nDvwEkwBHZIokgNwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9260a6289470aefc0fc0b88dfc21acc8/e4706/ioBroker_mqtt_10.avif 230w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/d1af7/ioBroker_mqtt_10.avif 460w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/7f308/ioBroker_mqtt_10.avif 920w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/006c2/ioBroker_mqtt_10.avif 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9260a6289470aefc0fc0b88dfc21acc8/a0b58/ioBroker_mqtt_10.webp 230w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/bc10c/ioBroker_mqtt_10.webp 460w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/966d8/ioBroker_mqtt_10.webp 920w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/cff29/ioBroker_mqtt_10.webp 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9260a6289470aefc0fc0b88dfc21acc8/81c8e/ioBroker_mqtt_10.png 230w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/08a84/ioBroker_mqtt_10.png 460w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/c0255/ioBroker_mqtt_10.png 920w", "/en/static/9260a6289470aefc0fc0b88dfc21acc8/97bfd/ioBroker_mqtt_10.png 1078w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9260a6289470aefc0fc0b88dfc21acc8/c0255/ioBroker_mqtt_10.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "lovelace-ui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lovelace-ui",
        "aria-label": "lovelace ui permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lovelace UI`}</h2>
    <p>{`In later I used the official `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Vis`}</code>{` UI. Let's take a look at one of the alternatives - Lovelace. Open the `}<strong parentName="p">{`Adapters`}</strong>{` page and install the `}<strong parentName="p">{`Lovelace Adapter`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d202395d961beed55b80fafaf8bd765/7a1fb/ioBroker_Lovelace_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVQ4y+WUy27TQBRAvUXQJk6TNA8nTmzHM35ESZNGsZEqUSpaAY1AKgIaJW2jioQCZcUCtsAW+CQ+gI86yFNS2kIXrdixOBrPtefc62vPaNlGh6VcnvTiLaQUbG1tsbm5yWB7wMbGBr1ejziOT4mimDiKiOPfRFFEv99Xo2YHHRrhCnmjjuNKtai90qbVadJqtZRQCIntuLhC4kuBG7RpyABHhjgyIOg0aa+2CfwAbfDlOwl3v/2g8/iIQFqs9e8wefia8f6Y6XTGeDTkcLzD48EDBrv7PBvt83x3yHA0YrL3lL3hDqu9HkEQoHXXt2nevke1GWM2fIR0WFtd582jj0wOD5hOX/L26JDjz195cjDj/mjGZPaK2XTK8dELjt9/YPTuE6EnkJ6H1rBrWGaFxZs3KJcKNJtNnIaDI2zCMFRZa6ZJqmiTLVaQjkW328XzfSqGwcJSiYzh4EmB7/tolm1Tq9dJ6zqGUVESKSTSl1iOhWmaZLNZ9NQCheU8XhhgN2xKpRKZTAY9nSK3pKsESlir1ahUKqRSKQzDOKnQcUjiVbNKPp8nnU6zvFxQ9+v1ukpSLpfRdZ1sNqcKkVKeCG3rpIq5MKnQ8zxc10UIocY5yVy44o94QiJL1ml+GFKtVs8Jk77Nx/n12fllcSXs+aF6jbNC/1c/rsKp0M4VVIOTPl0UXqzoMs4JF/W0kv0zYfLpE/5j4fyB6/BXYbJT5v/XVVGnzVlhsVhU28627WthWRY/AcxpHNbdchHCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d202395d961beed55b80fafaf8bd765/e4706/ioBroker_Lovelace_1.avif 230w", "/en/static/3d202395d961beed55b80fafaf8bd765/d1af7/ioBroker_Lovelace_1.avif 460w", "/en/static/3d202395d961beed55b80fafaf8bd765/7f308/ioBroker_Lovelace_1.avif 920w", "/en/static/3d202395d961beed55b80fafaf8bd765/db5a9/ioBroker_Lovelace_1.avif 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d202395d961beed55b80fafaf8bd765/a0b58/ioBroker_Lovelace_1.webp 230w", "/en/static/3d202395d961beed55b80fafaf8bd765/bc10c/ioBroker_Lovelace_1.webp 460w", "/en/static/3d202395d961beed55b80fafaf8bd765/966d8/ioBroker_Lovelace_1.webp 920w", "/en/static/3d202395d961beed55b80fafaf8bd765/a820a/ioBroker_Lovelace_1.webp 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d202395d961beed55b80fafaf8bd765/81c8e/ioBroker_Lovelace_1.png 230w", "/en/static/3d202395d961beed55b80fafaf8bd765/08a84/ioBroker_Lovelace_1.png 460w", "/en/static/3d202395d961beed55b80fafaf8bd765/c0255/ioBroker_Lovelace_1.png 920w", "/en/static/3d202395d961beed55b80fafaf8bd765/7a1fb/ioBroker_Lovelace_1.png 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d202395d961beed55b80fafaf8bd765/c0255/ioBroker_Lovelace_1.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here I changed the port for the UI to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8123`}</code>{` and left the rest default:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/6bfd0/ioBroker_Lovelace_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC5ElEQVQ4y42TTU8TURSGZ8HGENqZFhRoZ6CldHrn605nOtPSFoqtrcEYg7hQF2pSYkTcuHGHxg0Y4z/QhUb5AS50Y/wxrk2UxMSlec259JZSWbh4ck/OPfOej3tGmWE1pGZmoakqGGPo9XrodDrY2NgQdhRFqNVq/8XKygoUo8CwmMshnZ5GPp9Ht9tFu90WYnSura2J4KgWoV6vi4/iOBaJoihGHFcHdoRKpQLFKlexXFiCOjWJzPwcVusraLVaaDSbaDQaWF9fRxRHCKIAjWYDzWYT5XIZlUqIiu+C2yYqYSCSeJ4HZfPVF1w5+Iz1/a9wr96HFa/C4QGYZaHEGCw6SyWBbdsCs1iE7XJ43ZswW1tgDodtWSgWTSjN248R3djFcu8eCmEL9qXrYC6HxUpCjJBC0rYsBq8cItp6CO/aDmwewrYYTLMExZibhn4hjckJBdn5C4gCH5xz+L4vcF13KDwK5x5820QpbwgxSkRzVPJLBejGApKqBt0wxJBpRmEYigASdRxHCI9CScthCMfjQx/NUVlYWIBhGEgmk8hkMmKwJEhC4y3Ltmm9JKN3pmlCoQApmM1mRRZaDzrH26SPKBElJKjK0Tt6OMXnHLquI5FIgKqlvQuCQARTELUrIR+tEo2DINHRe1opJcjmkMvlhi3LrONiEtn2WTFiD1VNg6ZpUFVVCFJ19DdQBWywhxL6iMZB58kKnYhTvJJKpUBIQcpCyHUZ30Fqc/zFJZRISWnHgtQyPQ7NSGYbr0D6z9rL4aMMK0wmoesG4moNjuv9g8t9eNyH7biwXe80Ax+znBFBTcP87Hk4xRx8VgAfIG1neRHFnA7zDFheB1siOzsQTKeRnJpE3quh9+gl2jsHuLz7Ap2dA2G3H+yjtf0cF/t7aG8/Rbt/muqdPUR3nyG69UQKTiNxbgLWRh+bH/+g9uY7eh9+oPXuJ5pvj+kdHmH702/0xyBf9/AIa+9/ofP6G/4CIYNAtXNC4MgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f96a48d6f2a0f242f06840f1d98b5d28/e4706/ioBroker_Lovelace_2.avif 230w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/d1af7/ioBroker_Lovelace_2.avif 460w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/7f308/ioBroker_Lovelace_2.avif 920w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/ecfe0/ioBroker_Lovelace_2.avif 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f96a48d6f2a0f242f06840f1d98b5d28/a0b58/ioBroker_Lovelace_2.webp 230w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/bc10c/ioBroker_Lovelace_2.webp 460w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/966d8/ioBroker_Lovelace_2.webp 920w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/7ed23/ioBroker_Lovelace_2.webp 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f96a48d6f2a0f242f06840f1d98b5d28/81c8e/ioBroker_Lovelace_2.png 230w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/08a84/ioBroker_Lovelace_2.png 460w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/c0255/ioBroker_Lovelace_2.png 920w", "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/6bfd0/ioBroker_Lovelace_2.png 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f96a48d6f2a0f242f06840f1d98b5d28/c0255/ioBroker_Lovelace_2.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the button to open the UI in a new tab or just open `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`localhost:8123`}</code>{` inside your browser:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/22db3cbba94839fa4441a0670eda456a/6bfd0/ioBroker_Lovelace_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACh0lEQVQ4y72Ty27bRhhGiexSpLKkOIBj62I5lsi50JREmTRJO7xIlqXoYiVKLwGCdtEAAbLKM3TTRd4ni/bhTkCqlpugXTgosjiYwfzDM9+A8xuPxBnVR3tUymWEEEymU8bjMfP5nMlkQhAERFFEGIWE4d/8c/4FRuNYcHjY4uHubjH6nkev28N1XQbuAK01UsgNUmJruxiVUmilb9EaJRWG7Pl02sfsPLjPk6NDksshXhRwfhERnAcMvAGu5+KdeQRRQJIlxbzb76JPNMpWCC2QWmJJC2P14U+e/fGR+Pe/cF6/5WLhES8S/JWPGimszMJdumQ/Z/jXPv1nfUQmCqzUwkxNzMQs5iIRGOev3uOt39EZ/srRKOX6ymUVu/See5yMbdpJGz218ZZe8XF/1kcMBZ2ks5HdkJlYiYXReLxLfe8h390zaHb2OHkRYF25BCsfZ+IUm+2pjb/0ceduIc7Rl/ozYX6TIuHRk2PqzSY7pSo1VSP9aUb2fMlguUnSTtv0lj3iH+PiAO/a43R5ih7rImUn3ZAnFKnAaDabNBoNSg9KHNotokXI2dDjdH6KShUyk0WyeB2jM41z6SBTWZDXt2QKnWiM/Ank0lKpROuoyfq316zf/MJolhFPYpJpQrrMmK/nrF6tWPywYDgbEl/FRf2GfN/Tq6cYXcehXq9T+n6HWrtG8nJEMIvpJg4iEgXO0CGchnhjj8FogLpQiFBs6znyXCJDidGvtWi1WpTLZQ72D1BCIiyBtOTtg7Y2azds17/AMi2McqVCpVLZCA8O0LZGqk0n3JW8dY1qtUrOVpi3mpR35lZY+XfhXZJ9Lqx+I+HXX/l/SPifP2V/f39b+BpM0+QTeY4qdOnoAnkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22db3cbba94839fa4441a0670eda456a/e4706/ioBroker_Lovelace_3.avif 230w", "/en/static/22db3cbba94839fa4441a0670eda456a/d1af7/ioBroker_Lovelace_3.avif 460w", "/en/static/22db3cbba94839fa4441a0670eda456a/7f308/ioBroker_Lovelace_3.avif 920w", "/en/static/22db3cbba94839fa4441a0670eda456a/ecfe0/ioBroker_Lovelace_3.avif 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/22db3cbba94839fa4441a0670eda456a/a0b58/ioBroker_Lovelace_3.webp 230w", "/en/static/22db3cbba94839fa4441a0670eda456a/bc10c/ioBroker_Lovelace_3.webp 460w", "/en/static/22db3cbba94839fa4441a0670eda456a/966d8/ioBroker_Lovelace_3.webp 920w", "/en/static/22db3cbba94839fa4441a0670eda456a/7ed23/ioBroker_Lovelace_3.webp 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22db3cbba94839fa4441a0670eda456a/81c8e/ioBroker_Lovelace_3.png 230w", "/en/static/22db3cbba94839fa4441a0670eda456a/08a84/ioBroker_Lovelace_3.png 460w", "/en/static/22db3cbba94839fa4441a0670eda456a/c0255/ioBroker_Lovelace_3.png 920w", "/en/static/22db3cbba94839fa4441a0670eda456a/6bfd0/ioBroker_Lovelace_3.png 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/22db3cbba94839fa4441a0670eda456a/c0255/ioBroker_Lovelace_3.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can click to edit the dashboard:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/047b3899b48f8b09744d75fb33497378/89048/ioBroker_Lovelace_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApUlEQVQY052QTQ6CMBhEewO/inGhKAq0oZWWnxaJRKI773+iMTSauJLo4uUlk5nNsPH+QNeP8L6HMTWEVJBSIcsk0lTMkosCynpo61CaFsy6IQSnyqGsPcrKB2vbQpsv2BbKtKHfnK9wlxuabgBbH3LQ9gAeH8Hjt+dZvvqrJMNOagjbYJMXYAsiEHFQ8G9M2yiKsE8SKDVdJcE45+BECP4T+tg/AWJWh5KUwA5FAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/047b3899b48f8b09744d75fb33497378/e4706/ioBroker_Lovelace_4.avif 230w", "/en/static/047b3899b48f8b09744d75fb33497378/d1af7/ioBroker_Lovelace_4.avif 460w", "/en/static/047b3899b48f8b09744d75fb33497378/7f308/ioBroker_Lovelace_4.avif 920w", "/en/static/047b3899b48f8b09744d75fb33497378/4f997/ioBroker_Lovelace_4.avif 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/047b3899b48f8b09744d75fb33497378/a0b58/ioBroker_Lovelace_4.webp 230w", "/en/static/047b3899b48f8b09744d75fb33497378/bc10c/ioBroker_Lovelace_4.webp 460w", "/en/static/047b3899b48f8b09744d75fb33497378/966d8/ioBroker_Lovelace_4.webp 920w", "/en/static/047b3899b48f8b09744d75fb33497378/a3537/ioBroker_Lovelace_4.webp 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/047b3899b48f8b09744d75fb33497378/81c8e/ioBroker_Lovelace_4.png 230w", "/en/static/047b3899b48f8b09744d75fb33497378/08a84/ioBroker_Lovelace_4.png 460w", "/en/static/047b3899b48f8b09744d75fb33497378/c0255/ioBroker_Lovelace_4.png 920w", "/en/static/047b3899b48f8b09744d75fb33497378/89048/ioBroker_Lovelace_4.png 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/047b3899b48f8b09744d75fb33497378/c0255/ioBroker_Lovelace_4.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "website-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#website-card",
        "aria-label": "website card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Website Card`}</h3>
    <p>{`Click on `}<strong parentName="p">{`Add a card`}</strong>{` and seach for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`website`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d9d0265c478ea5da2ee80365da580916/b5dee/ioBroker_Lovelace_5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/UlEQVQ4y41Ty27bMBB00ZMlG5YtknpQtt7Ww7bixC7QAAVyyrWXHor+QP//D6aYdRWlTVD0MNjVkpyZJVez5vKI7vIZVV2j73v0XYf+dIfuONzyvkfbtijLEmmaIooipLudII7jF1TDA9rrI2aLxQKet8bp/oLTwxXtcUAz3KM7X9CReDijOZxQFIWQVlWFpmlQ17XUxm+tNRzXxcx1XSyXS+R5jrIocDwe0ez3SKyVWpZl6LpOXPKbJGPtcDiIa+ZxFGE+n0+EbMVaC2sTOchWqeorhcBoaKXg+z6M0RLDMJQz6/VaBKLXhGybhR3vJk2ljWEYoJSC0Ro23yNOSxitpEYSz/NekCQJjDFwHGdySEUu5MXt8oMgQBhGsKHG9etPnJ9/SG5MII4oSnAvz8kdvkdokwTJLpXINqkcxAmCyErOdulwxGq1kld+Q8iWx7aT7VZyuvT9W9v6d7u3mn8TCgJsNhtst9v3HXITH4QbmLMmM1ZVUqcIa3y8cWS4ThNvCKk2qjISdDQ+EkeDpHTEO+Qc/vMOqUQyKlNxdMfIv4gE02hZmUuCeZplUNrAcRcTIRWoTvCOGOnwNQnJuUZR/h1ERoc2hvGWcD5++PNRxmFlHB3+Da4zslUipliaYfPpGc7d0zTYVKaj/wX3+/4Gip2FFs7TdzhfvuEXh5puNEMYpSsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9d0265c478ea5da2ee80365da580916/e4706/ioBroker_Lovelace_5.avif 230w", "/en/static/d9d0265c478ea5da2ee80365da580916/d1af7/ioBroker_Lovelace_5.avif 460w", "/en/static/d9d0265c478ea5da2ee80365da580916/7f308/ioBroker_Lovelace_5.avif 920w", "/en/static/d9d0265c478ea5da2ee80365da580916/a66a0/ioBroker_Lovelace_5.avif 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d9d0265c478ea5da2ee80365da580916/a0b58/ioBroker_Lovelace_5.webp 230w", "/en/static/d9d0265c478ea5da2ee80365da580916/bc10c/ioBroker_Lovelace_5.webp 460w", "/en/static/d9d0265c478ea5da2ee80365da580916/966d8/ioBroker_Lovelace_5.webp 920w", "/en/static/d9d0265c478ea5da2ee80365da580916/3deba/ioBroker_Lovelace_5.webp 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9d0265c478ea5da2ee80365da580916/81c8e/ioBroker_Lovelace_5.png 230w", "/en/static/d9d0265c478ea5da2ee80365da580916/08a84/ioBroker_Lovelace_5.png 460w", "/en/static/d9d0265c478ea5da2ee80365da580916/c0255/ioBroker_Lovelace_5.png 920w", "/en/static/d9d0265c478ea5da2ee80365da580916/b5dee/ioBroker_Lovelace_5.png 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d9d0265c478ea5da2ee80365da580916/c0255/ioBroker_Lovelace_5.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And add your camera's `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9420_WQHD/Video_Streaming/"
      }}>{`MJPEG streaming URL`}</a>{` to add the live video:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/89048/ioBroker_Lovelace_6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB70lEQVQ4y52TXY+aQBSGvWm6MIgm7bYMIB8igwr4gWiNsRfbNGr2wgtT9cIfYOIf6M9/mzMEsmbZ3aQXT84wMM+cOWdozH49I3/aIYpTJNMZ4kmG4XiKNMsh4hS+6KMbDd4kiIYY5wv8+P2M7GmHhuF4sFwfTqeDSAiIMETYC9CPBHzPhWWasC0L3DAqTF7AZeTwPQ9Gx4XR8dBQlQeoioIwDDEcDuH7PrrdriTo9RAKIcc0H8cJgiCAaZp3fH18BGMqFOUBDU3TQAghMBqNkCSJFJcxiiL0SByGGI/Hcp6eSUzQ++VyCV3XwTQNDcaYFHqeJxcR9CFlRXOu68JxHDmmTUkwGAzkZhRpk+12WwgZK4TNZrNKn3N+dxzLsmQkYb/fr2RxHEuyLMNms3ktpIW2bddC76iGpYiOnaZpJaQMyVErfCkux5QhlYAkBB2T4mQykbLD4SDL9qGwhITUCBKQbDqdSuFqtcL5fMbxeHw/w5eUQmrWbDZDnueYz+dSul6vcb1ecblc3smwpobUqMVigf1+LzmdTjIrirfbDbvdDqqqSmkh1DSYHRdmEMH0gldZltxdaM7BbQffbBffuYmmxuTlru4htb2l6zLW0Wq1amlTbLfB2l/A9Fbxp5D0v6C1nz9B6aZgp79gP//gHyRlfyJDUYE8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/e4706/ioBroker_Lovelace_6.avif 230w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/d1af7/ioBroker_Lovelace_6.avif 460w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/7f308/ioBroker_Lovelace_6.avif 920w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/4f997/ioBroker_Lovelace_6.avif 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/a0b58/ioBroker_Lovelace_6.webp 230w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/bc10c/ioBroker_Lovelace_6.webp 460w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/966d8/ioBroker_Lovelace_6.webp 920w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/a3537/ioBroker_Lovelace_6.webp 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/81c8e/ioBroker_Lovelace_6.png 230w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/08a84/ioBroker_Lovelace_6.png 460w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/c0255/ioBroker_Lovelace_6.png 920w", "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/89048/ioBroker_Lovelace_6.png 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f34f7476eefe9c60a4d17a3e0b0ebbac/c0255/ioBroker_Lovelace_6.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/02e3a295399786f2ab913b583b7242c0/b5dee/ioBroker_Lovelace_7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDElEQVQ4y42TSY/aQBCFOUSZCAgXBN7xbuMFbGxjFkHQRJocwowmlxkkxCUH4P//ghdVkU5EgpI5PJXd7f7qVXW5UT88oVzdI8lzpOUM8aRCUtSIJ1P4af4GZUiLKeb3Dyg/f0VjEMTQTRuObSMahvBcF2EQwPdcqIoMRZagyJcoSxJHWheiPV1TEUYxDD9C48PdHSRJQpGNkGUZojhGTEoSpGmK8XiMYRTBsm2EYQjbcaAoClRVvUjT0O/3Yds2Oh/baDSbTd74VFdYzWrMZjMGkwiW5zknCIIAVVUhSRI4jsMAiqZpoigK/qbT6fwG1lWFqiz50GQyQZaN+SMCEIwAFEnD4RBRFPGe7/vYbrdsoN1uo9FqtdDr9WAMBtB1nWUYBks8a5rGbgRIJBqNRvxOQKrmCkiH6eCfonXqmeu6v0DUW9FfAj4+Pv4NFE6ES5FAAD3PY0ckOkyRWjOfz3E4HG6U/B8g9Y4uiEQgcrher7nc0+nEa8R6M5D6R7dJKsuSXW42G+x2OxyPR05006FGsJ9RAGkKLMviMqfTKYscLZdL7Pd7HE8ndnzt8B+XQkBKSBewWCx4Vuu6ZvC352ecz2ce/mbramxMaAaNjnFVtiidgDQ6VDKBKNKNj7McL6+vCBwbzffvLsButwvFciFbLhTdgEL/qKLclPjlxLtsmFBtD73VE1pfvuMHUD6P88E+0RwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02e3a295399786f2ab913b583b7242c0/e4706/ioBroker_Lovelace_7.avif 230w", "/en/static/02e3a295399786f2ab913b583b7242c0/d1af7/ioBroker_Lovelace_7.avif 460w", "/en/static/02e3a295399786f2ab913b583b7242c0/7f308/ioBroker_Lovelace_7.avif 920w", "/en/static/02e3a295399786f2ab913b583b7242c0/a66a0/ioBroker_Lovelace_7.avif 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/02e3a295399786f2ab913b583b7242c0/a0b58/ioBroker_Lovelace_7.webp 230w", "/en/static/02e3a295399786f2ab913b583b7242c0/bc10c/ioBroker_Lovelace_7.webp 460w", "/en/static/02e3a295399786f2ab913b583b7242c0/966d8/ioBroker_Lovelace_7.webp 920w", "/en/static/02e3a295399786f2ab913b583b7242c0/3deba/ioBroker_Lovelace_7.webp 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02e3a295399786f2ab913b583b7242c0/81c8e/ioBroker_Lovelace_7.png 230w", "/en/static/02e3a295399786f2ab913b583b7242c0/08a84/ioBroker_Lovelace_7.png 460w", "/en/static/02e3a295399786f2ab913b583b7242c0/c0255/ioBroker_Lovelace_7.png 920w", "/en/static/02e3a295399786f2ab913b583b7242c0/b5dee/ioBroker_Lovelace_7.png 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/02e3a295399786f2ab913b583b7242c0/c0255/ioBroker_Lovelace_7.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/76ad7d19cc441aade207873d8c3a02fb/7a4b2/ioBroker_Lovelace_8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4klEQVQ4y5WTzaraYBCGvQE1UTEmtRxqa9UUj1EERRGP/yD+bBR/F6KuBL0BN+7dCAouRBcuBMELEO/AG3L7lpnWQ1pOqQZeMoT5nm/emYnBLFpAEizWP/Tl6ze4PV58fnmBJCtwKJ9YFEuyDNGqy9cxDKIgQC+B3qIIt9uNoKYh8PqKH6oKj+c7x5qmIRQKwUpAsxl/nzcIooi7CERAi8UCl8vFh9/eUkgkEgzx+/2Ix+MoFouw2+0wE1B3nmQQf4NEHZBu93q9CAQCyOVySKfTDKNL6FupVILD4XgH6vUhkCr0+XwIBoMoFAqoVCqoVqvodrsYDAYcy7IMk8n0HJCqoeqoos1mg/P5jFarhUajAUVRngOqqso9TKVSbHu5XOJ0OiEajSKfz3POfYD/BVIPyW44HEY2m0Wn08FkMmHoaDRCrVaDJEmP95CANNlyuYzZbIZ+v49MJsPWY7EYksnkO5DyaeI2m43jD4GUOJ/PuWeXywXX6xX7/R7b7RbT6RTtdvvXHj5imWQ0GnE4HLhni8UCx+MR+ud2u8HpdHLeQ5bpTdZogcfjMQ+CrK/Xa+x2O6xWq+d7WK/XMRwO2V6v1+MdpLjZbPJe3nv2cIWRSIR3kAZAvxsN6b5CFP9rbX4CW56rk+xDRckAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/76ad7d19cc441aade207873d8c3a02fb/e4706/ioBroker_Lovelace_8.avif 230w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/d1af7/ioBroker_Lovelace_8.avif 460w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/7f308/ioBroker_Lovelace_8.avif 920w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/87c15/ioBroker_Lovelace_8.avif 1240w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/76ad7d19cc441aade207873d8c3a02fb/a0b58/ioBroker_Lovelace_8.webp 230w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/bc10c/ioBroker_Lovelace_8.webp 460w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/966d8/ioBroker_Lovelace_8.webp 920w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/9eb34/ioBroker_Lovelace_8.webp 1240w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/76ad7d19cc441aade207873d8c3a02fb/81c8e/ioBroker_Lovelace_8.png 230w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/08a84/ioBroker_Lovelace_8.png 460w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/c0255/ioBroker_Lovelace_8.png 920w", "/en/static/76ad7d19cc441aade207873d8c3a02fb/7a4b2/ioBroker_Lovelace_8.png 1240w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/76ad7d19cc441aade207873d8c3a02fb/c0255/ioBroker_Lovelace_8.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you set up  `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WebRTC_Livevideo_Smarthome/"
      }}>{`go2RTC to re-stream your camera's RTSP stream`}</a>{` you can also add the WebRTC stream which will run a lot smoother:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/19a15/ioBroker_Lovelace_9.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQoz32Ry27bMBBFs5ZlCZZliyJFiXrQelt2Hs6jyaJpgOYzmrbrxIu2v3+LYZTERoEuDjikoDND3hM9XEB3GyRJgq5tUVeVgWqVxAhDBsE5eBgaqH6Dc45ICJRlibzbQg/nOJlMLLiug5XWyPIcSimkaWrWLMuQj2dxHJumIYlHGdWMMQRBgKltY2JZJJzAcRyUVY22acx0VVmirmvTmSBxkRfmTEoJlSjEMjbN6Dud2bZteBcWdYtu2KJdb7BqOlRVBa01iqIwE8tIoqkbpCrF7mKH+8/3uNxd4vrqGtvNFpZlfQhd1wUXEcIRqoUQhih6rUMW4uz0DCu9wuPXR/z+9Qefbm5xd3uHvl//K6QfPxBHexIGywDDesDDlwfs93s8fXvCy/MLfnz/iSBgx1cmoZmI0hsnO4QC8H0fLGBmT8/Q9z2apjEpe94M0+kU5BqFDqJEIco0IpVCysg89CGH01K6lCzjAjxWEDI+FlLkM3+B2ZLB8xfwPO+/zOfzVxZLeMsAs7lvrkvCvwth9BO3inKdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/e4706/ioBroker_Lovelace_9.avif 230w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/d1af7/ioBroker_Lovelace_9.avif 460w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/7f308/ioBroker_Lovelace_9.avif 920w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/74c0d/ioBroker_Lovelace_9.avif 1229w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/a0b58/ioBroker_Lovelace_9.webp 230w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/bc10c/ioBroker_Lovelace_9.webp 460w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/966d8/ioBroker_Lovelace_9.webp 920w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/24859/ioBroker_Lovelace_9.webp 1229w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/81c8e/ioBroker_Lovelace_9.png 230w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/08a84/ioBroker_Lovelace_9.png 460w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/c0255/ioBroker_Lovelace_9.png 920w", "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/19a15/ioBroker_Lovelace_9.png 1229w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5fe5dc2e7f61b93c06e3f309109cacc7/c0255/ioBroker_Lovelace_9.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "picture-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#picture-card",
        "aria-label": "picture card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Picture Card`}</h3>
    <p>{`To add a still image you can select a `}<strong parentName="p">{`Picture Glance`}</strong>{` card:`}</p>
    <p>{`![ioBroker with Docker]`}{`(./ioBroker_Lovelace_10 .png)`}</p>
    <p>{`And add your camera's `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9420_WQHD/Video_Streaming/"
      }}>{`JPG URL`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca800db1aa271253f7d4ba20f94ee41d/89048/ioBroker_Lovelace_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACIklEQVQ4y3WSu4/aQBDG3d1hcwUPG7/t9RvbPE1AQkgoR4pLJFAKpNRXUqWDhgbxh3/R7N0Gk7sUnzy7nv3NfLMrVc/fMXt+QVYOUIynKMcV8tEUxbhCOZkh6pcI0j6CNP9UYZpjOJtj/u0Hpl9fIBkug+UHcB0HSRxxRVGIOI6QJjE814FlmlymYdxk3uIoimCzELrrQ5Ibj5AbDcRxjCRJwBhDEARgJMYQRhFf06HJZII8L1BVFabTKZbLJVarFYbDIdqtFh4fHiA1m02QCEg/8jxHURQoy5JrMBjwQgRdLBY83m63OBwOvMB6vcZ8Pke704GiKG9ACiiRqo5GIw4isIBTMep2s9lgv9/jcrngdDrhfD7j9fUVWZah2+1ClmVIBCMoWaJuCEJwWpMEjPao0G63w/F4xPV65V8qQnmqqt4DyRLB6iChMAxhWRZs24au6/xwr9fjXZEo5wOQIGma8kMkskF2Rbe0J6BCjuPwPcqpAZtoKgrsIIbHAv4cKImSXdflorgOqss0TQ7UNO2+Q58xRHHMrQfvT+bvE2LsrsP6l4Dk4NNLKd+fSH1+/7NMsed5PPZ9H+12++OlFHmOLE04RIjmSuB/rb4BXdiWyR10OLABSRHvMOtj/GWBMM34rQrrwjIB6nIcG7brwfJ8sCBEq6tCbj7dOlR7OjTLhWpYfMA0G3GT4oLIophrr6dBM0xouoGuH+Pp528o61/4A4EpiO4CiFMZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca800db1aa271253f7d4ba20f94ee41d/e4706/ioBroker_Lovelace_11.avif 230w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/d1af7/ioBroker_Lovelace_11.avif 460w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/7f308/ioBroker_Lovelace_11.avif 920w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/4f997/ioBroker_Lovelace_11.avif 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca800db1aa271253f7d4ba20f94ee41d/a0b58/ioBroker_Lovelace_11.webp 230w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/bc10c/ioBroker_Lovelace_11.webp 460w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/966d8/ioBroker_Lovelace_11.webp 920w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/a3537/ioBroker_Lovelace_11.webp 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca800db1aa271253f7d4ba20f94ee41d/81c8e/ioBroker_Lovelace_11.png 230w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/08a84/ioBroker_Lovelace_11.png 460w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/c0255/ioBroker_Lovelace_11.png 920w", "/en/static/ca800db1aa271253f7d4ba20f94ee41d/89048/ioBroker_Lovelace_11.png 1242w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca800db1aa271253f7d4ba20f94ee41d/c0255/ioBroker_Lovelace_11.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can also use the `}<a parentName="p" {...{
        "href": "#adding-your-camera-live-stream-using-the-onvif-service"
      }}>{`ONVIF Service`}</a>{` to grap the snapshot URL:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/89a86ac9338448eb39961f9478079769/cd78c/ioBroker_Lovelace_12a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChklEQVQ4y2WUzWsTQRjGK0ghu5s1pEk2u7Ob/Zid/YzNmqCtWAsqJP3QSsGrh9aeiiC1WBD1oqVC1RYRRY+eLIgX/QMfeadNm7SHh5mdZX/7zPO+MxNJ7yaS7gw8z0eapsjyHGmSIMsytNtttFotMMYwNVVFtVpFrVZDrVaXqlQqqNfrSLMMSfcGiDWhlcvQdR1BEIBzDs/z4Pv+qQLOYds2Fpcf4OHqKhYWB+j372BhcBdr6+uYm5+HZZloNk0oiooJVVVBEkJIV1EUjSlJU+lw68UOjv78xrcfX3B4sIvD/Vf49/cI28+ewDDqsCwLpVKJgBo0TZPuaMvkNAzDU9GPfM9FzfSw9+kAX79/xu67l/j4fgdPNx/DdU1YzIRpmkOgKoFRJHCtKDA9PS3BkRDHDpMEjm1jfWMDP49+4cPhPl6/2cbe220839rEZGkSTdM451BVEcYp8qKLKI4hohg8FFJRnKDl+RgM+ri/soK5+TnMzF7H7EwXS0sD5GmAluNcdBiICFnRQ5RfBY8ziKyNMM3B4xRBlMjqUqWpqo1GA42GIdcMo4kwFGg2m+NAchFyDh4E8H0PQoTymbbrOI4UtdBwfvzsgDFLZj0GLGsaWCDger6sKLUJY7Yc6UPbdsDkGhsTvafsLgDJIbnJ81wWIo7jMVGl7RMgjaOiNepXikJRlJEMOUeWHQNH24bET5p7DHi6EyYPQ42AqnYGJBfUMgSk+VDDH5wHshMosyz4BNRVKJcvnbWNH0aIOj20wliGP5TrujJHysoeyVHCXA/M47ADgUrvHpT2rTOH5bIupetX5KEniGEY8kLodDryWBKUYEVRSDjdAxVdR9lgUPprUG4/wn89+pHAqyoA/AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89a86ac9338448eb39961f9478079769/e4706/ioBroker_Lovelace_12a.avif 230w", "/en/static/89a86ac9338448eb39961f9478079769/d1af7/ioBroker_Lovelace_12a.avif 460w", "/en/static/89a86ac9338448eb39961f9478079769/7f308/ioBroker_Lovelace_12a.avif 920w", "/en/static/89a86ac9338448eb39961f9478079769/2adae/ioBroker_Lovelace_12a.avif 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/89a86ac9338448eb39961f9478079769/a0b58/ioBroker_Lovelace_12a.webp 230w", "/en/static/89a86ac9338448eb39961f9478079769/bc10c/ioBroker_Lovelace_12a.webp 460w", "/en/static/89a86ac9338448eb39961f9478079769/966d8/ioBroker_Lovelace_12a.webp 920w", "/en/static/89a86ac9338448eb39961f9478079769/5b1dd/ioBroker_Lovelace_12a.webp 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89a86ac9338448eb39961f9478079769/81c8e/ioBroker_Lovelace_12a.png 230w", "/en/static/89a86ac9338448eb39961f9478079769/08a84/ioBroker_Lovelace_12a.png 460w", "/en/static/89a86ac9338448eb39961f9478079769/c0255/ioBroker_Lovelace_12a.png 920w", "/en/static/89a86ac9338448eb39961f9478079769/cd78c/ioBroker_Lovelace_12a.png 1236w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/89a86ac9338448eb39961f9478079769/c0255/ioBroker_Lovelace_12a.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To have the `}<strong parentName="p">{`Snapshot Entity`}</strong>{` show up here you need to find the corresponding entry in the object tree and click on the settings `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cog`}</code>{` symbol on the right:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/6bfd0/ioBroker_Lovelace_12b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAADMUlEQVQ4y42US28bVRiGvWAFSnxJipp4fBm78dzv9nguHsdu6jSxC6GpggoJLKASbVDFAhCwYIuQUJsVLFjA70BI/LUHnZM4dQpILF6d75yZ88z7nfN9U7plJNRu3aZSKWMYBocHB8xm93iwWMg4jmOyNCXLMtI0lRLxUsu1pUrNOwZtVWVzc5N2u0Wc5MTpiGGS4kVD0jTDjwZYtoPv+wRBgG3bWJYlFYahXBPjYDCgZPVzNE2nVqvRbCi4fogb9PH8EMMNSdKUeJhiWrbcJFw4joPrutK9+Ei/32c4HBJFESXbdrjT7VIrr6G0umTFlCLP8DwP2zLZPziUL9q2dQ1alaZp0pkA6rpO6einP5n/+AfjH/7CP/mSJM0lwDBNTNNk8e4xnh9gGLqcvy4BETDhttfrUYqPnhC+8wmd3RN2hvuMdvfIs/Ryg2XxUZTRtx1007w+t1UJoIC9Aja3UDYrvPlGiXqjTT6ZMcozTNPCNA2i4Rjb9WT8v4BttUOrrVIul2l0dYrpPkHgYxgmpmGwPz7C90JZUv8FvJGySK3ZalFeX0dpqsT5VF7GEmAal2cl439JWwDFpQiJCyr5noeiKNJhfXsLLy4YT/YoipEsEc/3XwGvIKvgfzgMFRVVValUKtS3b+NGCcloSuB7N9J83aUYxfOlQ1GL0mGlWqVarV4Bt3D8iN274mLyG05WYwESdSrKS7hKkkS2oXQoOkRIAut1WcCTyYSiKORL/krKq0DRNUkylBDhUByPdFirrgIVHNfj3myfg8M5UX+A7bhYQrZzLUO0YRRdAnWDNMsZ707QRKdcO6xW2Xp7k8DWyeKIJHTROw0iR8faaaOrDTkX0lQFW+vgWxrd5jahozPwLHbayhVwY4Py2lu0/IL73/7Oo+9/Y/H1L4w+vyB7+oL8/CX5+cXVeBkX5y+5/8UFe89fcPjVzyy++ZX+6XdLhxtU1tdo9Fz2PnzO7INn3H38jPHJZyQPn9BffEw0P7tWOD9jMD9j/OCM/vyU0fGnTN5/ijt9byVl8bdReyTpCKvXQeu06KkNjh+fEriX6ejdNlq3taI23ZbCMPSIA1fGfwMTfjS3sx4MyQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/e4706/ioBroker_Lovelace_12b.avif 230w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/d1af7/ioBroker_Lovelace_12b.avif 460w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/7f308/ioBroker_Lovelace_12b.avif 920w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/ecfe0/ioBroker_Lovelace_12b.avif 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/a0b58/ioBroker_Lovelace_12b.webp 230w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/bc10c/ioBroker_Lovelace_12b.webp 460w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/966d8/ioBroker_Lovelace_12b.webp 920w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/7ed23/ioBroker_Lovelace_12b.webp 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/81c8e/ioBroker_Lovelace_12b.png 230w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/08a84/ioBroker_Lovelace_12b.png 460w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/c0255/ioBroker_Lovelace_12b.png 920w", "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/6bfd0/ioBroker_Lovelace_12b.png 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8e58aeb7bd71f27cf4d0840e135c81b3/c0255/ioBroker_Lovelace_12b.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And make sure that Lovelace is enabled:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4602266b9697fa8eb8f22c31f9669f58/6bfd0/ioBroker_Lovelace_12c.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACkklEQVQ4y62UTW/TQBCG/StQT5zSJrETx3ET2/m0E3vtNnHTJE2bj0I+EHYPqUQRl0r0wJ0TBxASCNRf+qKZNKWhrUDA4fHMzuy+nt0dW3ryNIFkKoVEIoFMJgPDMFAsFtkSuq5jd3cXOS2HXO5xNE3Dzs4OJNOysFyeI47PEEUx4ihGFEVsz+IzxPHKnk5OMR6PMRlP7jEejTGdTlGr1iBRBbbnw7BdmI4Ho1yCaRowLXMDq2yhVC49iFkyUbNrME0TkmEVcXDxEoNXIwwvhjg6H6D7povu6y7CKEQYh2hHbbTjNvs8jtsbtKIW+ss+rIoFqdY+wv54gPboEK3BPvb6e/B7PkRXwOt4EIcCQS+A23HRPGjCPXDZp9wairUGLd6JVKzYEEEIzwsgXA+9wx6aThN2zWaEK3AyOOE40Qk7CETAOafuMDSfYnSZkq7lIISHSqUM27bh+z6EEGg0Gmg0G3BdF0EQQPiCLcVtx+a5RN2uw3EczlFX8KX4QYB6vc6TSXAtuvZJdC1Aiwl+YaPBPs0Nw/CmQl3n21FV9bd9RjwUp7UkVigUINGDRFOpFGRZviWdTm/4j0F5WkvC9AFIhppjh5KKovwVtDafz68qpM/truDdKv+Eu4Jc4a+C/1LhfxOkSje2TINkMsmidMD3SSNNlvJp+SeywtyeIVWYzWZRrVb5pukNhULxxq5YtwPFi7oGQ5NhaMqKXJptRpH5lnWzsmob+l3NZjPM53O2s/mcfYJ+SzReTJ/hdHmJ/tuvGF59xtHlJxy/u8bg6gvef/iI6+/fcDyaQNra2uJzoK0pWRV6ocgNS82qqlmY5Qq0fB5qRoFs2Nj2niPVHGK7OUJyb4Ftd4Le8QgvFgve9g8/CGICV5QN3AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4602266b9697fa8eb8f22c31f9669f58/e4706/ioBroker_Lovelace_12c.avif 230w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/d1af7/ioBroker_Lovelace_12c.avif 460w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/7f308/ioBroker_Lovelace_12c.avif 920w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/ecfe0/ioBroker_Lovelace_12c.avif 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4602266b9697fa8eb8f22c31f9669f58/a0b58/ioBroker_Lovelace_12c.webp 230w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/bc10c/ioBroker_Lovelace_12c.webp 460w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/966d8/ioBroker_Lovelace_12c.webp 920w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/7ed23/ioBroker_Lovelace_12c.webp 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4602266b9697fa8eb8f22c31f9669f58/81c8e/ioBroker_Lovelace_12c.png 230w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/08a84/ioBroker_Lovelace_12c.png 460w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/c0255/ioBroker_Lovelace_12c.png 920w", "/en/static/4602266b9697fa8eb8f22c31f9669f58/6bfd0/ioBroker_Lovelace_12c.png 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4602266b9697fa8eb8f22c31f9669f58/c0255/ioBroker_Lovelace_12c.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once added the card will show up on your dashboard showing a snapshot from your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7316f224154d2107e697630685da2c8c/2bfc7/ioBroker_Lovelace_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVQ4y4WS2U8TURTGayKmi5neKbQ4HdJOC5QudKFMC+20aZ0uQAVFYgJNWIIgS4wIGpREDIawlASDC8ZqgMiLDybERP/Az9zLEiytPnw5k5tzfuc754zm/swSHswuoH/iEfJjc+gdn0d+dBbqyBQyxXNNM6nDD3F7eBL50Rn0jM0x0dyh2SUUn7zAvekFaGS1D8nCINT+u+jODyCavYOu/AAKxUn0jYyjUJxA3/Ao0+DEDIYm56EUhkDrIpkC5AppiNEIIxVnBE8IDHo9JMmB7z9OUD4o4+3eDrY2V7Hx5hm+HX7Er98/EZX90Om0IITWcX9Jw/M8KkUb9A4Monx0gA+f9lAqraG0sYzd0gpWVx4jHGiB3mCAyWS6UlsVyBkJutMFfD0+xv7n99jafo3S5jKDujwuXL9Rx9zRXPI/IMcZIYoC9vffoXx0iPXtdbxcWcTzxSns7qyhpzeDurprIIScAs9iTSAdt7GxEalMDl2xODrlToRCQfj9XigJBemUghanjY1bCasKPE+yCFZYrSKDn+oWBKsI4ey9Enj+XRVIk4PBIMLhMFwuF2w2GyTJDrvdjqamJjgcDgiCwKa5DKOxKrC+vh7t7e0IhUIMSmM0GkVHRwckSUI8Hocsy9DpdBeX/qfDhoYGeDwe+Hw+BozFYkgmk6yJ0+mEoiisgVarhcVigdlsvnBbExgIBNDa2srcpdNpJBIJeL1etLW1IZVKIRKJXDhk7mjkTbWBFNTc3Myc5nI5Nqbb7WZNqNvLQJ7+kwYtiEFXHUhHoEeh+6IHoI7ozq461MNEa8wWEF83eHdnbaDf72dAURSRzWbZyBRGXaqqyvaopUDuJojTB+7VCcjTL/gDjwTGBD/aztQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7316f224154d2107e697630685da2c8c/e4706/ioBroker_Lovelace_13.avif 230w", "/en/static/7316f224154d2107e697630685da2c8c/d1af7/ioBroker_Lovelace_13.avif 460w", "/en/static/7316f224154d2107e697630685da2c8c/7f308/ioBroker_Lovelace_13.avif 920w", "/en/static/7316f224154d2107e697630685da2c8c/29541/ioBroker_Lovelace_13.avif 1227w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7316f224154d2107e697630685da2c8c/a0b58/ioBroker_Lovelace_13.webp 230w", "/en/static/7316f224154d2107e697630685da2c8c/bc10c/ioBroker_Lovelace_13.webp 460w", "/en/static/7316f224154d2107e697630685da2c8c/966d8/ioBroker_Lovelace_13.webp 920w", "/en/static/7316f224154d2107e697630685da2c8c/79a76/ioBroker_Lovelace_13.webp 1227w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7316f224154d2107e697630685da2c8c/81c8e/ioBroker_Lovelace_13.png 230w", "/en/static/7316f224154d2107e697630685da2c8c/08a84/ioBroker_Lovelace_13.png 460w", "/en/static/7316f224154d2107e697630685da2c8c/c0255/ioBroker_Lovelace_13.png 920w", "/en/static/7316f224154d2107e697630685da2c8c/2bfc7/ioBroker_Lovelace_13.png 1227w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7316f224154d2107e697630685da2c8c/c0255/ioBroker_Lovelace_13.png",
              "alt": "ioBroker with Docker",
              "title": "ioBroker with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      